.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.externalLink {
  color: rgb(var(--colors-white));

  &:hover {
    text-decoration: underline;
  }
}

.icon {
  svg {
    width: 24px;
    height: 24px;
  }
}
