.wrapper {
  position: relative;
  margin-bottom: 40px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-color: rgb(var(--colors-black-2));
  padding: 24px 24px 40px;
  border-radius: 0 0 8px 8px;
}

.tableWrapper {
  overflow: hidden;
  padding: 0 0 0 40px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    bottom: 0;
    background-color: rgb(var(--colors-black-2));
    border-radius: 8px 0 0 0;
  }
}

.tableInner {
  width: 100%;
  overflow: auto;
}

.table {
  width: auto;
}

.tableTh {
  width: 140px;
  min-width: 140px;
  font-size: 14px;
  color: rgb(var(--colors-lantern-main-color));
  padding: 10px 12px;
  text-align: right;
  height: 56px;
  background-color: rgb(var(--colors-black));
  border-bottom: 1px solid rgb(var(--colors-gray-5));
  border-left: 2px solid transparent;
  background-clip: padding-box;

  &:first-child {
    width: 120px;
    min-width: 140px;
    font-size: 12px;
    text-align: left;
    border-left-width: 0;
    color: rgb(var(--colors-gray-4));
    background-color: rgb(var(--colors-black-2));
    position: sticky;
    z-index: 2;
    left: 0;
    padding-left: 0;
  }

  &:last-child {
    padding: 0;
    width: 100%;
    min-width: 0;
    border-left-width: 0;
    border-radius: 0 8px 0 0;
    background-color: rgb(var(--colors-black-2));
  }
}

.tableTd {
  width: 140px;
  min-width: 140px;
  font-size: 12px;
  height: 48px;
  padding: 10px 12px;
  text-align: right;
  background-color: rgb(var(--colors-gray-8));
  border-bottom: 1px solid rgb(var(--colors-gray-7));
  border-left: 2px solid transparent;
  background-clip: padding-box;

  &:first-child {
    width: 120px;
    white-space: nowrap;
    text-align: left;
    border-left-width: 0;
    background-color: rgb(var(--colors-black-2));
    position: sticky;
    z-index: 2;
    left: 0;
    padding-left: 0;
    font-size: 14px;
  }

  &:last-child {
    padding: 0;
    width: 100%;
    min-width: 0;
    border-bottom: none;
    background-color: rgb(var(--colors-black-2));
    border-left-width: 0;
  }
}

.overlay {
  z-index: 1;
}
