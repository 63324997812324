.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgb(var(--colors-gray-7));
  padding-top: 40px;
  font-size: 18px;
}

.noDocumentsContainerIcon {
  margin-bottom: 24px;
  
  svg {
    width: 32px;
    height: 32px;
  }
}
