.wrapper {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  line-height: 1;

  &.circle {
    border-radius: 50%;
  }
}

.loader {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: no-repeat;
  animation-duration: 1.5s;
  animation-direction: normal;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  &.animatedToRight {
    transform: translateX(-100%);
    animation-name: animate-to-right;
  }
  &.animatedToBottom {
    transform: translateY(-100%);
    animation-name: animate-to-bottom
  }
}

@keyframes animate-to-right {
  100% {
    transform: translateX(100%);
  }
}

@keyframes animate-to-bottom {
  100% {
    transform: translateY(100%);
  }
}
