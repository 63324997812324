@import 'src/styles/config';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &__primary {
    height: 100%;
    width: 100%;
  }

  &__secondary {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
}

.inner {
  width: 180px;
  height: 180px;
  margin: auto;
  background-color: rgb(var(--colors-black));
  border-radius: 26px;

  @include media('>=large') {
    width: 220px;
    height: 220px;
  }
}

.loader {
  width: 100%;
  height: 100%;
}
