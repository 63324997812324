@import "../../../../../styles/config";

.cell {
  width: 40px;
  margin: 0;
  padding: 0;
}

.cellInnerWrapper {
  height: 100%;
  background-color: rgba(var(--colors-black), .75);
}

.notVisible {
  display: none;
}
