.buttons {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: transform .2s ease;
  right: 0;
  gap: 16px;

  &__top {
    top: 20px;
  }

  &__fixed {
    position: fixed;
    bottom: 20px;
    right: 24px;
  }

  &__bottom {
    top: unset;
    bottom: -60px;
  }
}