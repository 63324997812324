.listItem {
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 22px;

  &.active {
    font-weight: 600;
  }

  &.inActive {
    opacity: 0.5;
  }
}

.listItemColor {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  flex: 1 1 8px;
  transform: translateY(-2px);
}

.listItemName {
  flex: 1 1 80%;
}

.listItemValue {
  text-align: right;
  flex: 1 1 20%;
  margin-left: 8px;
  white-space: nowrap;
}

.currency {
  font-size: 14px;
  margin-right: 5px;
}
