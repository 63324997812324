%toastOverrideDefault {
  border-radius: 8px !important;
  border: 1px solid rgba(255, 255, 255, 0.32) !important;
  background: linear-gradient(
                  91deg,
                  rgba(6, 188, 130, 0.32) -13.89%,
                  rgba(6, 188, 130, 0.32) 102.61%
  ),
  rgba(32, 32, 32, 0.8) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(3.5px) !important;
  color: white !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.Toastify__toast-container--top-right {
  top: 2em !important;
  right: 1.5em !important;
}

.Toastify__toast--success {
  @extend %toastOverrideDefault;
}

.Toastify__toast--error {
  @extend %toastOverrideDefault;
  background: linear-gradient(
                  0deg,
                  rgba(255, 6, 6, 0.32) 0%,
                  rgba(255, 6, 6, 0.32) 100%
  ),
  rgba(32, 32, 32, 0.8) !important;
}

.Toastify__toast--info {
  @extend %toastOverrideDefault;
  background: linear-gradient(
                  91deg,
                  rgb(var(--colors-gray-5)) -13.89%,
                  rgb(var(--colors-gray-5)) 102.61%
  ),
  rgba(32, 32, 32, 0.8) !important;
}
