@import '../../../styles/mixins.scss';

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: 'Halenoir';
  overflow: unset;
  z-index: 1;

  &__locked {
    position: absolute;
    overflow: hidden;
    height: 100vh;
  }
}

.modalWrapper {
  padding-top: 48px;
  transform: scale(1) translateY(0);
  transition: transform 0.3s ease;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.icon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--colors-white));
  margin-bottom: 24px;

  &__warning {
    background-color: rgb(var(--colors-red));
  }

  &__info {
    background-color: rgb(var(--colors-gray-5));
  }

  &__success {
    background-color: rgb(var(--colors-lan-green));
  }

  &__addChart {
    background-color: rgb(var(--colors-pigment-indigo));
  }

  &__orange {
    background-color: rgb(var(--colors-orange-peel));
  }

  svg {
    width: 32px;
    height: 32px;
  }
}

.message {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.singleButton {
  margin: 0 auto;
}

// Light theme buttons
@include buttons-light-theme;
