@import '../../../../../styles/config';

.wrapper {
  margin-top: 40px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerText {
  font-size: 24px;
  font-weight: 500;
}

.widgets {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 24px;

  &.isLoading {
    gap: 24px;
  }

  @include media('>=xlarge') {
    flex-direction: row;
  }
}

.widgetWrap {
  flex: 1 1 50%;
  padding: 32px 0;

  &:nth-child(1) {
    border-bottom: 1px solid rgb(var(--colors-gray-7));
  }

  @include media('>=xlarge') {
    padding: 0 32px;

    &:nth-child(1) {
      border-right: 1px solid rgb(var(--colors-gray-7));
      border-bottom: unset;
      padding: 0 32px 0 0;
    }
    &:nth-child(2) {
      padding: 0 0 0 32px;
    }
  }
}

.widgetInner {
  margin: -20px;
}

.capitalWidget {
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: 100% !important;
}

.asAt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.widgetItem {
  flex: 1 1 50%;
}

.headerContent {
  display: flex;
  gap: 24px;
}
