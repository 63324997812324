@import '../../../../styles/mixins';

.cell {
  font-size: 14px;
}

.edit {
  @include displayOnTableRowHover;
}

.status {
  display: table-row;
  vertical-align: middle;
  height: 56px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  height: 56px;
}
