@import '../../../styles/recharts';

.wrapper {
  @extend %simpleRechartsTooltip;
  flex-direction: column;
  gap: 2px;
}

.treemapTooltipInfoHolder {
  display: flex;
  align-items: center;
}

.showMore {
  pointer-events: auto;
  text-decoration: underline;
}
