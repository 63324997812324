.wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 48px;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 9px;
}

.title {
  font-size: 48px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.message {
  font-size: 16px;
  color: rgb(var(--colors-gray-5));
  padding-bottom: 16px;
}
