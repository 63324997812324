.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 24px;
}

.bar {
  width: 4px;
  height: 24px;
  margin-top: 0;
  margin-right: 12px;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: -6px;
    bottom: 0;
    left: -4px;
    right: -4px;
  }
}
