@import '../../../../../styles/config';

.cell {
  margin: 0;
  padding: 0 2px 0 0;;
  color: rgb(var(--colors-white));
  background-color: rgba(var(--colors-black), .75);
}

.innerWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 16px;
  margin: 0;
  color: rgb(var(--colors-white));
  background-color: rgb(var(--colors-gray-8));
  border-top: 1px solid rgb(var(--colors-gray-7));
}

.cellText {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.notVisible {
  display: none;
}

.fakeColumnCell {
  background-color: rgba(var(--colors-black), .75);
}

