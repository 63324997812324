@import '../../../../../styles/config';

.row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 40px;
  padding: 0 0 40px 0;
  margin: 0;
}

.groupRowLeft {
  width: 30%;
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(var(--colors-gray-7));
}

.groupRowRight {
  width: 70%;
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(var(--colors-gray-7));
}

.groupHeaderCellSpan {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(var(--colors-white));
}

.question {
  font-size: 16px;
  font-weight: 500;
  color: rgb(var(--colors-white));
  padding-bottom: 24px;
}

.mandatoryMark {
  color: rgb(var(--colors-lan-green));
}

.infoIcon {
  color: rgb(var(--colors-gray-4));
}

.tooltip {
  max-width: 180px;
  padding: 12px;
  border-radius: 8px;
}
