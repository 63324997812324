.cell {
  .textareaWrapper:first-of-type {
    padding: 0;
  }
}

.textareaWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgb(var(--colors-gray-6));
  border: 1px solid rgb(var(--colors-lan-green));
  padding: 0;
}

.textarea {
  width: 100%;
  background: none;
  max-width: 100%;
  color: rgb(var(--colors-white));
  resize: none;
  font-family: inherit;
  font-size: 14px;
  line-height: 14px;
  padding: 16px;
  overflow: hidden;
  border: none;

  &:focus {
    outline: none;
  }
}

.textareaRight {
  text-align: right;
}