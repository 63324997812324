.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .groupTitle {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 16px;
  }

  .disabled {
    color: rgb(var(--colors-gray-6));
  }
  
  .quantityGroup {
    display: flex;
    flex-direction: row;
  }  
  
  .quantityTitle {
    font-size: 14px;
  }  
  
  .icons {
    margin-left: 9px;
    display: flex;
    gap: 16px;

    div {
      cursor: pointer;
    }
  
    .disabled {
      color: rgb(var(--colors-gray-5));
      pointer-events: none;
    }
  }
}

.entityDetailsGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 25px;
}
  
.entityDetails {
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: baseline;
}
  
.entityDetailsLabel {
  font-size: 14px;
  color: rgb(var(--colors-gray-3));
}
