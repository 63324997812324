@import '../../../../../styles/config';

.cell {
  margin: 0;
  padding: 0 2px 0 0;
  background-color: rgba(var(--colors-black), .75);
}

.cellInnerWrapper {
  height: 100%;
  margin: 0;
  padding: 15px 12px 15px 12px;
  color: rgb(var(--colors-white));
  background-color: rgb(var(--colors-black));
  font-size: 14px;
  border-bottom: 1px solid rgb(var(--colors-gray-5));
}

.notVisible {
  display: none;
}
