@import 'functions';

body[data-theme='dark'] {
  // primary colors
  --colors-lan-green: #{hexToRGB(#05db97)};
  --colors-lan-blue: #{hexToRGB(#00b8fd)};
  --colors-lan-rose: #{hexToRGB(#ff31d7)};
  --colors-lan-violet: #{hexToRGB(#9900ff)};

  --colors-lantern-main-color: #{hexToRGB(#ffffff)};

  // supporting colors
  --colors-green-haze: #{hexToRGB(#05a370)};
  --colors-tropical-forest: #{hexToRGB(#007c54)};
  --colors-pacific-blue: #{hexToRGB(#009cbf)};
  --colors-allports: #{hexToRGB(#0076a2)};
  --colors-flirt: #{hexToRGB(#9c0073)};
  --colors-pompadour: #{hexToRGB(#760052)};
  --colors-pigment-indigo: #{hexToRGB(#6000a5)};
  --colors-revolver: #{hexToRGB(#3d0065)};
  --colors-turbo: #{hexToRGB(#efe600)};
  --colors-olive: #{hexToRGB(#969b00)};
  --colors-bronze-yellow: #{hexToRGB(#717500)};
  --colors-orange-peel: #{hexToRGB(#ffa100)};
  --colors-chelsea-gem: #{hexToRGB(#9c6100)};
  --colors-cinnamon: #{hexToRGB(#754800)};

  // status colors
  --colors-harlequin: #{hexToRGB(#42e800)};
  --colors-limeade: #{hexToRGB(#2faa00)};
  --colors-japanese-laurel: #{hexToRGB(#1e7500)};
  --colors-red: #{hexToRGB(#eb0000)};
  --colors-sangria: #{hexToRGB(#b00000)};
  --colors-lonestar: #{hexToRGB(#750000)};
  --colors-blue-pencil: #{hexToRGB(#2b00ea)};
  --colors-navy-blue: #{hexToRGB(#2000b3)};
  --colors-insomniac-blue: #{hexToRGB(#150075)};

  //Grayscale colors
  --colors-white: #{hexToRGB(#ffffff)};
  --colors-gray-1: #{hexToRGB(#f5f5f5)};
  --colors-gray-2: #{hexToRGB(#e4e4e4)};
  --colors-gray-3: #{hexToRGB(#cfcfcf)};
  --colors-gray-4: #{hexToRGB(#a5a5a5)};
  --colors-gray-5: #{hexToRGB(#858585)};
  --colors-gray-6: #{hexToRGB(#626262)};
  --colors-gray-7: #{hexToRGB(#464646)};
  --colors-gray-8: #{hexToRGB(#2d2d2d)};
  --colors-black: #{hexToRGB(#202020)};
  --colors-black-2: #{hexToRGB(#1e1e1e)};

  // additional colors
  --colors-lantern-dark: #{hexToRGB(#202020)};
  --colors-lantern-gray: #{hexToRGB(#b2b2b2)};
  --colors-lantern-gray2: #{hexToRGB(#858585)};

  // near-casting supporting colors
  --colors-nearcasting-gradient1-transparent: #{hexToRGB(#7517c8)};
  --colors-nearcasting-gradient2-transparent: #{hexToRGB(#472de3)};
  --colors-nearcasting-gradient1: #{hexToRGB(#7517c8)};
  --colors-nearcasting-gradient2: #{hexToRGB(#472de3)};
  --nearcasting-gradient: linear-gradient(127deg, rgba(var(--colors-nearcasting-gradient1-transparent), 0.28) 2.91%,
          rgba(var(--colors-nearcasting-gradient2-transparent), 0.28) 100%);
  --nearcasting-gradient-medium: linear-gradient(127deg, rgba(var(--colors-nearcasting-gradient1-transparent), 0.55) 2.91%,
          rgba(var(--colors-nearcasting-gradient2-transparent), 0.55) 100%);
  --nearcasting-gradient-solid: linear-gradient(111deg, rgb(var(--colors-nearcasting-gradient1)) 0%,
          rgb(var(--colors-nearcasting-gradient2)) 72.58%);
  --colors-nearcasting-merchant-marine-blue: #{hexToRGB(#074fdb)};

  // sector chart colors
  --colors-sector-chart-blue-1: #{hexToRGB(#004a66)};
  --colors-sector-chart-blue-2: #{hexToRGB(#006990)};
  --colors-sector-chart-blue-3: #{hexToRGB(#0089bc)};
  --colors-sector-chart-blue-4: #{hexToRGB(#00b1d9)};
  --colors-sector-chart-blue-5: #{hexToRGB(#1fc1ff)};

  --colors-sector-chart-violet-1: #{hexToRGB(#6f00be)};
  --colors-sector-chart-violet-2: #{hexToRGB(#a31aff)};
  --colors-sector-chart-violet-3: #{hexToRGB(#b366ff)};
  --colors-sector-chart-violet-4: #{hexToRGB(#c286ff)};
  --colors-sector-chart-violet-5: #{hexToRGB(#e0b2ff)};
}

body[data-theme='light'] {
  // primary colors
  --colors-lan-green: #{hexToRGB(#05db97)};
  --colors-lan-blue: #{hexToRGB(#00b8fd)};
  --colors-lan-rose: #{hexToRGB(#ff31d7)};
  --colors-lan-violet: #{hexToRGB(#9900ff)};

  --colors-lantern-main-color: #{hexToRGB(#000000)};

  // supporting colors
  --colors-green-haze: #{hexToRGB(#05a370)};
  --colors-tropical-forest: #{hexToRGB(#007c54)};
  --colors-pacific-blue: #{hexToRGB(#009cbf)};
  --colors-allports: #{hexToRGB(#0076a2)};
  --colors-flirt: #{hexToRGB(#9c0073)};
  --colors-pompadour: #{hexToRGB(#760052)};
  --colors-pigment-indigo: #{hexToRGB(#6000a5)};
  --colors-revolver: #{hexToRGB(#3d0065)};
  --colors-turbo: #{hexToRGB(#efe600)};
  --colors-olive: #{hexToRGB(#969b00)};
  --colors-bronze-yellow: #{hexToRGB(#717500)};
  --colors-orange-peel: #{hexToRGB(#ffa100)};
  --colors-chelsea-gem: #{hexToRGB(#9c6100)};
  --colors-cinnamon: #{hexToRGB(#754800)};

  // status colors
  --colors-harlequin: #{hexToRGB(#42e800)};
  --colors-limeade: #{hexToRGB(#2faa00)};
  --colors-japanese-laurel: #{hexToRGB(#1e7500)};
  --colors-red: #{hexToRGB(#eb0000)};
  --colors-sangria: #{hexToRGB(#b00000)};
  --colors-lonestar: #{hexToRGB(#750000)};
  --colors-blue-pencil: #{hexToRGB(#2b00ea)};
  --colors-navy-blue: #{hexToRGB(#2000b3)};
  --colors-insomniac-blue: #{hexToRGB(#150075)};

  //Grayscale colors
  --colors-white: #{hexToRGB(#ffffff)};
  --colors-gray-1: #{hexToRGB(#f5f5f5)};
  --colors-gray-2: #{hexToRGB(#e4e4e4)};
  --colors-gray-3: #{hexToRGB(#cfcfcf)};
  --colors-gray-4: #{hexToRGB(#a5a5a5)};
  --colors-gray-5: #{hexToRGB(#858585)};
  --colors-gray-6: #{hexToRGB(#626262)};
  --colors-gray-7: #{hexToRGB(#464646)};
  --colors-gray-8: #{hexToRGB(#2d2d2d)};
  --colors-black: #{hexToRGB(#202020)};

  // additional colors
  --colors-lantern-dark: #{hexToRGB(#202020)};
  --colors-lantern-gray: #{hexToRGB(#b2b2b2)};
  --colors-lantern-gray2: #{hexToRGB(#858585)};

  // near-casting supporting colors
  --colors-nearcasting-gradient1-transparent: #{hexToRGB(#7517c8)};
  --colors-nearcasting-gradient2-transparent: #{hexToRGB(#472de3)};
  --colors-nearcasting-gradient1: #{hexToRGB(#7517c8)};
  --colors-nearcasting-gradient2: #{hexToRGB(#472de3)};
  --nearcasting-gradient: linear-gradient(127deg, rgba(var(--colors-nearcasting-gradient1-transparent), 0.28) 2.91%,
          rgba(var(--colors-nearcasting-gradient2-transparent), 0.28) 100%);
  --nearcasting-gradient-medium: linear-gradient(127deg, rgba(var(--colors-nearcasting-gradient1-transparent), 0.55) 2.91%,
          rgba(var(--colors-nearcasting-gradient2-transparent), 0.55) 100%);
  --nearcasting-gradient-solid: linear-gradient(111deg, rgb(var(--colors-nearcasting-gradient1)) 0%, rgb(var(--colors-nearcasting-gradient2)) 72.58%);
  --colors-nearcasting-merchant-marine-blue: #{hexToRGB(#074fdb)};

  // sector chart colors
  --colors-sector-chart-blue-1: #{hexToRGB(#004a66)};
  --colors-sector-chart-blue-2: #{hexToRGB(#006990)};
  --colors-sector-chart-blue-3: #{hexToRGB(#0089bc)};
  --colors-sector-chart-blue-4: #{hexToRGB(#00b1d9)};
  --colors-sector-chart-blue-5: #{hexToRGB(#1fc1ff)};

  --colors-sector-chart-violet-1: #{hexToRGB(#6f00be)};
  --colors-sector-chart-violet-2: #{hexToRGB(#a31aff)};
  --colors-sector-chart-violet-3: #{hexToRGB(#b366ff)};
  --colors-sector-chart-violet-4: #{hexToRGB(#c286ff)};
  --colors-sector-chart-violet-5: #{hexToRGB(#e0b2ff)};
}

:root {
  --toastify-toast-width: 540px !important;
  --toastify-toast-min-height: 52px !important;
  --toastify-font-family: 'Halenoir' !important;
}
