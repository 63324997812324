@import '../../../styles/config';

.wrapper {
  width: 100%;
  padding: 0 20px;

  @include media('>=medium') {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
}

.left {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.right {
  @include media('>=medium') {
    display: flex;
    align-items: center;
    font-size: 24px;
  }
}

.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Halenoir';

  &.large {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
  }

  @include media('>=small') {
    font-size: 20px;
  }

  @include media('>=medium') {
    font-size: 20px;
    line-height: 26px;

    &.large {
      font-size: 32px;
      line-height: 40px;
    }
  }

  @include media('>=large') {
    font-size: 24px;
    line-height: 32px;

    &.large {
      font-size: 32px;
      line-height: 40px;
    }
  }

  @include media('>=xxlarge') {
    font-size: 26px;

    &.large {
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.count {
  border-radius: 6px;
  background: rgba(var(--colors-white), 0.12);
  margin: 0 8px;
  padding: 4px;
  font-size: 16px;
  min-width: 28px;
  text-align: center;

  @include media('>=small') {
    min-width: 40px;
    font-size: 20px;
    padding: 8px;
  }

  @include media('>=medium') {
    margin: 0 16px;
  }

  @include media('>=large') {
    min-width: 45px;
    font-size: 24px;
    margin: 0 24px;
    line-height: 32px;
  }
}

.button {
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  height: 40px;
  margin: 10px 0 0 0;

  @include media('>=medium') {
    width: auto;
    margin: 0 0 0 10px;
  }
}
