.icon {
  svg {
    animation: rotate .8s linear infinite;
    width: 48px;
    height: 48px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
