@import '../../../styles/config';

.wrapper {
  padding: 64px 56px;
  border-radius: 8px;
  color: rgb(var(--colors-white));
  background: rgba(var(--colors-black), .75);
  font-size: 16px;
  font-weight: 400;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 24px;
  font-size: 22px;
  font-weight: 500;
}

.description {
  margin: 0;
}

.beta {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 500;
}
