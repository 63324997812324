.title {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}

.value {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;

  div {
    display: inline;
    opacity: .8;
  }
}
