.wrapper {
  width: 63px;
  height: 63px;
  border-radius: 50%;
  background-color: rgb(var(--colors-pacific-blue));
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;

  svg, img {
    width: 100%;
    height: 100%;
    display: block;
  }

  &.letter {
    &_w {
      background-color: rgb(var(--colors-green-haze));
    }
    &_d {
      background-color: rgb(var(--colors-pacific-blue));
    }
    &_m {
      background-color: rgb(var(--colors-flirt));
    }
    &_j {
      background-color: rgb(var(--colors-pompadour));
    }
    &_h {
      background-color: rgb(var(--colors-revolver));
    }
  }
}
