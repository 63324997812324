.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  user-select: none;

  &__primary {

    .icon {
      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border: 2px solid rgb(var(--colors-gray-6));
        border-radius: 2px;
      }
    }

    .input {
      &:checked + .icon {
        &:before {
          background-color: rgb(var(--colors-lan-green));
          border-color: rgb(var(--colors-lan-green));
        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          width: 10px;
          height: 6px;
          border-left: 1.5px solid rgb(var(--colors-black));
          border-bottom: 1.5px solid rgb(var(--colors-black));
          left: 3px;
          top: 50%;
          transform: translateY(-4px) rotate(-45deg);
        }
      }

      &:indeterminate + .icon {
        &:before {
          background-color: rgb(var(--colors-lan-green));
          border-color: rgb(var(--colors-lan-green));
        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          width: 10px;
          height: 1.5px;
          left: 3px;
          top: 50%;
          background-color: rgb(var(--colors-black));
          transform: translateY(-1px);
        }
      }

      &:disabled + .icon {
        color: rgb(var(--colors-gray-6));

        &:before {
          border-color: rgb(var(--colors-gray-6));
          background-color: rgb(var(--colors-gray-7));
        }
      }

      &:disabled:checked + .icon,
      &:disabled:indeterminate + .icon, {
        &:before {
          background-color: rgb(var(--colors-gray-6));
        }
      }
    }

    &:hover {

      .icon:before {
        border-color: rgb(var(--colors-gray-4));
      }
    }
  }

  &__secondary {
    .icon {
      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
      }
    }

    .input {
      &:checked + .icon {
        &:after {
          display: block;
          content: '';
          position: absolute;
          width: 10px;
          height: 6px;
          border-left: 1.5px solid rgb(var(--colors-lan-green));
          border-bottom: 1.5px solid rgb(var(--colors-lan-green));
          left: 3px;
          top: 50%;
          transform: translateY(-4px) rotate(-45deg);
        }
      }

      &:disabled:checked + .icon {
        &:after {
          background-color: rgb(var(--colors-gray-4));
        }
      }
    }
  }
}

.input {
  display: none;
}

.icon {
  display: flex;
  position: relative;
}

.label {
}
