.wrapper {
  display: flex;
  align-items: center;
  gap: 3px;
}

.item {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
