.wrapper {
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 50%;
  position: relative;
  margin-left: auto;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
  }

  &__ready {
    border: 3px solid rgb(var(--colors-gray-7));
  }

  &__pending {
    border: 3px solid rgb(var(--colors-gray-7));

    &:before {
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: rgb(var(--colors-lantern-main-color));
      animation: rotate 1s linear 0s infinite;
    }
  }

  &__success {
    background-color: rgb(var(--colors-lan-green));

    &:before {
      width: 5px;
      height: 10px;
      border-bottom: 1px solid rgb(var(--colors-black));
      border-right: 1px solid rgb(var(--colors-black));
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%) rotate(45deg);
    }
  }

  &__failure {
    background-color: rgb(var(--colors-red));

    &:before, &:after {
      width: 10px;
      height: 1px;
      background-color: rgb(var(--colors-lantern-main-color));
      top: 50%;
      left: 50%;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
