.wrapper {
    position: relative;
}

.button {
    width: 16px;
    height: 16px;
    position: relative;
    padding: 0;
    border: 0;
    border-radius: 2px;
    background: transparent;
    cursor: pointer;

    &Active {
        background: #fff;

        .dots circle {
            fill: rgb(var(--colors-gray-7));
        }
    }
}

.dots {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    svg {
        height: 3px;
    }
}

.menuModal {
    &>div {
        position: absolute;
        right: 0;
        left: unset;
        top: 22px;
        height: unset;
        width: unset;
        align-items: center;
        justify-content: flex-end;

        &>div {
            border-radius: 8px;
            background: #FFF;
            display: flex;
            width: 173px;
            padding: 16px 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            &>div {
                width: 100%;

                .modalRowBreak {
                    height: 1px;
                    background-color: #E4E4E4;
                    margin: 8px 0;
                    width: 100%;
                }

                .modalRowDelete {
                    color: var(--Status-Sangria, #B00000);
                }

                .modalRow {
                    width: 100%;
                    cursor: pointer;
                    padding: 8px 16px;
                }
            }
        }
    }
}