@import '../../../styles/config';

.wrapper {
  font-weight: 400;

  &__primary {
    background-color: rgba(var(--colors-black), 0.75);
    border-radius: 8px;
    padding: 48px 40px;
    height: 100%;
  }

  &__secondary {
    background-color: transparent;
    border-radius: 0;
    padding: 20px;
    height: 100%;
  }
}

.title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
  font-family: 'Halenoir';
}

.currency {
  font-size: 14px;
}

.columns {
  display: flex;
  align-items: flex-start;
}

.list {
  flex: 1 1 60%;
}

.group {
  margin: 40px 0;
}

.groupList {
  padding: 16px 0 0 40px;
  border-top: 1px solid rgb(var(--colors-gray-6));
}

.chart {
  position: relative;
  max-width: 340px;
  margin: 0 0 0 40px;
  flex: 1 1 40%;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 100%;
  }
}

.chartInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  g:focus,
  path:focus {
    outline: none;
  }
}

.chartTooltip {
  font-size: 24px;
  text-align: center;

  sub {
    font-size: 20px;
  }
}

.chartTooltipCurrency {
  font-size: 12px;
  margin-right: 2px;
}
