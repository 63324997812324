@import "../../../../../styles/config";

.cell {
  margin: 0;
  padding: 8px 12px 8px 0;
  color: rgb(var(--colors-gray-4));
  background-color: rgba(var(--colors-black), .75);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.notVisible {
  display: none;
}
