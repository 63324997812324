@import '../../../../../styles/mixins';

.modalWrapper {
  background: rgb(var(--colors-gray-8));
  color: rgb(var(--colors-white));
  width: 1180px;
  transition: all .3s ease-in-out;
}

.fileUploader {
  padding: 40px;

  [data-file-uploader-top] {
    padding: 0 66px 0 0;

    [data-file-uploader-upload-block] {
      flex: 0 0 555px;
    }
  }
}

.fileUploadContent {
  overflow-y: auto;
  max-height: calc(60vh - 185px);

  .tagsWrapper {
    margin-bottom: 40px;
  }

  .infoWrapper {
    margin-bottom: 40px;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: rgb(var(--colors-gray-8));
  }

  @include scrollBarStyles;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.documentsTable {
  background: transparent;
  padding: 0;
}
