.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconWrapper {
  display: flex;
  min-width: 35px;
}

.previous,
.next {
  display: flex;
  transform: scale(1.5);
  cursor: pointer;
}

.previous {
  margin-right: 18px;
}

.next {
  margin-left: 18px;
}

.page {
  cursor: pointer;
  user-select: none;

  &__dots {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(var(--colors-gray-3));
    font-size: 0;
    line-height: 0;
    color: transparent;
    margin: 0 6px;

    &.active {
      transform: scale(1.35);
      background-color: rgb(var(--colors-lan-green));
    }
  }

  &__numbers {
    min-width: 20px;
    font-size: 14px;
    margin: 0 8px;

    &.active {
      color: rgb(var(--colors-lan-green));
    }
  }
}
