@import 'src/styles/config';

.top {
  padding: 0 0 8px 0;
}

.topRow {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 16px;
}

.topRight {
  text-align: right;
  color: rgb(var(--colors-gray-4));
  white-space: nowrap;
}

.topLeft {
  padding: 0 0 0 32px;
  position: relative;

  .logo {
    position: absolute;
    top: 2px;
    left: 0;
  }
}

.companyName {
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  margin: 0 0 8px;
  text-decoration: none;
  color: rgb(var(--colors-lantern-main-color));
  font-family: 'Halenoir';

  &:hover {
    text-decoration: underline;
  }
}

.fundInfo {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgb(var(--colors-gray-4));
}

.delta {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgb(var(--colors-gray-4));
  padding: 0 0 0 28px;
  position: relative;

  @include media('>=small') {
    font-size: 14px;
    line-height: 18px;
  }

  svg {
    width: 10px;
    height: 6px;
    position: absolute;
    left: 8px;
    top: 4px;
  }
}

.specIcons {
  display: flex;
  align-items: center;
}

.specIconsItem {
  margin-right: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.asAtWrapper {
  padding-bottom: 4px;
  padding-top: 8px;
}

.asAt {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.asAtIcon {
  margin-right: 8px;
  color: rgb(var(--colors-lan-rose));
}

.smallText{
  text-transform: lowercase;
}