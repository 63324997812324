.wrapper {
  background-color: rgba(var(--colors-black), 0.75);
  border-radius: 8px;
  padding: 24px;
  min-height: 289px;
  height: 100%;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 26px;
  margin: 0 0 37px;

  span {
    font-family: 'Halenoir';
  }
}
