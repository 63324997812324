@import 'src/styles/mixins';

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .spinner {
    color: rgb(var(--colors-gray-6));
    svg {
      width: 48px;
      height: 48px;
    }
  }
}

.socialMedia {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 12px;
  align-items: center;
}

.socialMediaIcon svg {
  width: 24px;
  height: 24px;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.inputHelper {
  font-size: 14px;
  margin-inline-start: 4px;
  padding-inline-start: 10px;
  color: rgb(var(--colors-gray-5));
  border-inline-start: 2px solid rgb(var(--colors-allports));
}

.groupTitle {
  font-size: 14px;
  color: rgb(var(--colors-gray-5));
}

.groupDescription {
  font-weight: 300;
  font-size: 14px;
  color: rgb(var(--colors-gray-5));
  margin-bottom: 12px;
}

.dateInputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.radioButtons {
  > div {
    border-bottom: none;
    padding-inline-start: 0;
  }
}

.buttons {
  @include drawerFormButtons();
}

.errorMessage {
  @include drawerFormError();
}
