@import '../../../styles/config';

.wrapper {
  margin: 80px 0;
}

.sectionHeader {
  margin-bottom: 16px;
}

.transactionsSectionRight {
  @include media('>=medium') {
    display: flex;
    align-items: center;
    font-size: 24px;
    gap: 24px;
  }
}

.liveDataTableRow {
  &:not(:hover) {
    background-color: rgb(var(--colors-gray-8));
  }
}
