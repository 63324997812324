@import "../../../../../styles/config.scss";

.wrapper {
  display: flex;
  padding: 12px;
}

.commitment,
.available {
  padding: 8px;
}

.available {
  margin-left: 50px;
}

.label {
  font-size: 14px;
  font-weight: 500;
}

.value {
  font-size: 16px;

  span {
    font-size: 16px;
    margin-right: 5px;
  }
}

@include media(">=small") {
  .wrapper {
    padding: 24px;
  }
  .label {
    font-size: 18px;
  }

  .value {
    font-size: 44px;
  }
}
