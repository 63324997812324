.wrapper {
  display: flex;
  align-items: center;
  padding: 16px 8px 16px 0;
  word-break: break-word;

  &:last-of-type {
    padding-right: 0;
  }

  &__visible {
    visibility: visible;
  }

  &__hidden {
    visibility: hidden;
  }

  &__start {
    justify-content: flex-start;
  }

  &__center {
    justify-content: center;
  }

  &__end {
    justify-content: flex-end;
  }

  &.isSubsidiary {
    padding-left: 60px;
  }
}
