.wrapper {
  background: rgb(var(--colors-gray-8));
  padding: 48px 48px 32px 48px;
}

.formTop {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
}

.formInputs {
  display: grid;
  grid-template-columns: 308px 308px;
  gap: 24px 16px;
}

.status {
  .statusLabel {
    font-size: 14px;
    margin-bottom: 15px;
    color: rgb(var(--colors-gray-5));
  }
}

.formBottom {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.sideButtons {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
