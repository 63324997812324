.wrapper {
  width: 100%;
}

.value {
  display: flex;
  align-items: baseline;
  font-size: 20px;
  font-weight: 400;

  sub {
    font-size: 14px;
  }
}

.currency {
  font-size: 12px;
  margin-right: 4px;
  word-break: normal;
}

.delta {
  align-self: center;
}
