.wrapper {
  display: flex;
  flex-direction: column;
  width: 216px;
  padding: 24px;
  border-right: 1px solid rgba(var(--colors-gray-2));
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(var(--colors-black));

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  li:hover {
    color: rgba(var(--colors-black));
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }
}

.icon {
  visibility: hidden;
}

.icon__checked {
  visibility: visible;
}

.text__checked {
  color: rgba(var(--colors-black));
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.disabledClick {
  pointer-events: none;
}
