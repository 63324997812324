@import '../../../styles/config.scss';
@import '../../../styles/mixins.scss';

.overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--colors-black), .5);
  display: none;

  &.isOpen {
    display: block;
  }
}

.wrapper {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);
  background-color: rgb(var(--colors-black));
  width: 560px;
  max-width: 100vw;
  transition: transform .3s ease;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow-y: auto;

  &.isOpen {
    transform: translateX(0);
  }

  @include media('>=xlarge') {
    display: none;
  }
}

.top {
  display: flex;
  height: 60px;
  flex: 0 0 60px;
  align-items: center;
  position: relative;
  border-bottom: 1px solid rgb(var(--colors-gray-6));
  padding: 0 20px;

  @include media('>=large') {
    height: 80px;
    flex: 0 0 80px;
  }
}

.closeButton {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.closeButtonIcon {
  color: rgb(var(--colors-white));

  svg {
    width: 16px;
    height: 16px;
  }
}

.logo {
  margin: 0 auto;

  svg {
    width: 128px;
  }
}

.navMenu {
  width: 75%;
  padding: 24px 20px;

  hr {
    margin: 24px 0;
    border: none;
    border-bottom: 1px solid rgb(var(--colors-gray-6));
  }
}

.link {
  display: flex;
  align-items: center;
  padding: 0 20px 0 84px;
  height: 56px;
  border-radius: 0 30px 30px 0;
  border-left: 4px solid transparent;
  position: relative;
  margin: 0 0 0 -20px;
  font-size: 16px;
  line-height: 22px;
  color: rgb(var(--colors-lantern-main-color));
  text-decoration: none;
  transition: all .3s ease;

  &.active {
    background-color: rgb(var(--colors-gray-8));
    color: rgb(var(--colors-lan-green));
    border-left-color: rgb(var(--colors-lan-green));

    .icon {
      color: rgb(var(--colors-lan-green));
    }
  }

  @include disabled-nav-menu-link;
}

.icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: rgb(var(--colors-white));

  svg {
    width: 24px;
    height: 24px;
  }
}

.userInfo {
  margin: auto 0 0 0;
  width: 75%;
  padding: 0 0 36px 20px;
  position: relative;
}

.userImage {
  position: absolute;
  top: 36px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.userIcon {

  svg {
    width: 40px;
    height: 40px;
  }
}

.userList {
  padding: 36px 0 0 64px;
  border-top: 1px solid rgb(var(--colors-gray-6));
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  li {
    color: rgb(var(--colors-lantern-main-color));
    white-space: nowrap;
    margin: 0 0 34px 0;

    button, a {
      font-family: inherit;
      color: rgb(var(--colors-lantern-main-color));
      border: none;
      background: none;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      padding: 0;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.userEmail {
  color: rgb(var(--colors-gray-5));
}
