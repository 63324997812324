.wrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 8px;
    position: relative;

    &.isClickable {
        cursor: pointer;
    }

    &.isLoading {
        pointer-events: none;
    }
}

.filePreview {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: top;
}

.spinnerWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(var(--colors-black), .7);

    .spinner {
        color: rgb(var(--colors-white));

        svg {
            width: 32px;
            height: 32px;
        }
    }
}
