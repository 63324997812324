@import '../../../../styles/mixins';

.wrapper {
  padding: 32px 32px 16px 32px;
  background: rgb(var(--colors-gray-8));
  border-radius: 8px;

  .reportingPeriods {
    background-color: rgb(var(--colors-gray-8));
    padding: 0;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: rgb(var(--colors-gray-8));
  }

  .scroll {
    overflow-y: auto;
    max-height: 400px;

    @include scrollBarStyles;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.footer {
  display: flex;
  margin-top: 16px;
  gap: 16px;
  justify-content: flex-end;
}

.title {
  font-size: 24px;
  font-weight: 500;
}
