.expandedRow {
  margin-bottom: 24px;
}

.table {
  table {
    width: 100%;

    tr {
      border: none;
    }
  }
}
