.tooltipWrapper {
  background: rgb(var(--colors-gray-7));
  padding: 12px;
  border-radius: 8px;
}

.date {
  font-family: 'HalenoirText', sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
}

.metricGroup {
  padding-top: 12px;
  border-top: 1px solid rgb(var(--colors-gray-6));
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.metricGroupTitle {
  font-family: 'HalenoirText', sans-serif;
  font-size: 12px;
  margin-bottom: 4px;
}

.metricValues {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: 'HalenoirText', sans-serif;
  font-size: 14px;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.color {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
