@import "../../../../styles/config";

.dateInput {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--colors-black));
  background: transparent;
  display: inline;
  width: 140px;
  padding: 12px 24px;
  margin-right: 16px;
  border: 1px solid rgba(var(--colors-gray-5));
  text-align: center;
  border-radius: 50px;
  outline: none;
}

.dateInput::placeholder {
  color: var(--colors-gray-4);
}

.dateInput:focus {
  border: 1px solid rgb(var(--colors-lan-green));
  color: rgba(var(--colors-gray-4));
}

.dateInput__invalid, .dateInput__invalid:focus {
  border: 1px solid rgba(var(--colors-red));
}

.dateInputField {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(var(--colors-gray-5));
}
