.tooltipWrapper {
  max-width: 295px;
  background: rgb(var(--colors-gray-7));
  padding: 12px;
  border-radius: 8px;
}
.labelWrapper {
  padding-bottom: 8px;
  border-bottom: 1px solid rgb(var(--colors-gray-6));
}

.valueWrapper {
  padding-top: 10px;
}

