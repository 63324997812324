@import './config';

// Micro clearfix: http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
@mixin clearfix() {
  &::after {
    content: '' !important;
    display: block !important;
    clear: both !important;
  }
}

// Applies accessible hiding to elements
@mixin hidden-visually() {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

// Disabled nav-menu-link
@mixin disabled-nav-menu-link() {
  &.disabled {
    color: rgb(var(--colors-gray-6));
    pointer-events: none;

    svg {
      color: rgb(var(--colors-gray-5));
    }
  }
}

// Dropdown controller active button
@mixin dropdown-controller-active() {
  &.active {

    .caret {
      transform: rotate(180deg);
    }
  }
}

// Button loading
@mixin button-loading() {
  &.loading {
    pointer-events: none;
    position: relative;
    justify-content: center;

    .text {
      opacity: 0;
    }

    .icon {
      opacity: 0;
    }
  }
}

// Buttons light theme mixin to be used where needed for now
@mixin buttons-light-theme() {
  .lightThemePrimaryButton {
    background-color: rgb(var(--colors-gray-8));
    color: rgb(var(--colors-lantern-main-color));

    &:hover, &:focus, &.loading {
      background-color: rgb(var(--colors-black));
      color: rgb(var(--colors-lantern-main-color));
      outline: none;
    }

    &:disabled {
      background-color: rgb(var(--colors-gray-6));
      color: rgb(var(--colors-gray-4));
    }
  }

  .lightThemeSecondaryButton {
    background-color: transparent;
    color: rgb(var(--colors-black));
    border: 1px solid rgb(var(--colors-gray-5));

    &:hover, &:focus, &.loading {
      color: rgb(var(--colors-gray-7));
      border: 1px solid rgb(var(--colors-gray-8));
      outline: none;
    }

    &:disabled {
      border: 1px solid rgb(var(--colors-gray-4));
      color: rgb(var(--colors-gray-4));
      background-color: transparent;
    }
  }
}

@mixin drawerFormButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

@mixin drawerFormError {
  background: rgb(var(--colors-red));
  font-size: 12px;
  padding: 5px;
  border-radius: 6px;
  margin-top: 10px;
  width: fit-content;

  &:first-child {
    margin-top: 0;
  }
}

@mixin scrollBarStyles {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(var(--colors-lantern-main-color), .3);
  }
}

@mixin transparentInput {
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: 'HalenoirText';
  font-weight: 500;
  flex: 1 1 auto;
  min-width: 50px;
  color: rgb(var(--colors-lantern-main-color));
  height: 30px;
  padding: 0;

  &::placeholder {
    color: rgb(var(--colors-gray-4));
  }
}

@mixin selectOption {
  display: block;
  position: relative;
  width: 100%;
  padding: 8px 40px;
  color: rgb(var(--colors-black));
  background: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border: none;
  cursor: pointer;
  text-align: left;
  word-break: break-word;

  &:hover {
    background-color: rgb(var(--colors-gray-1));
  }

  &.active {
    font-weight: 600;

    .optionIcon {
      opacity: 1;
    }
  }

  .optionIcon {
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 16px;
    margin-top: -8px;
    opacity: 0;
    color: rgb(var(--colors-lan-green));
  }
}

@mixin selectErrorMessage {
  position: absolute;
  bottom: 100%;
  margin-bottom: -4px;
  right: 18px;
  background: rgb(var(--colors-red));
  font-size: 12px;
  padding: 8px;
  color: rgb(var(--colors-white));
  border-radius: 6px;
  display: none;

  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: rgb(var(--colors-red));
    position: absolute;
    bottom: -4px;
    right: 26px;
    transform: rotate(45deg);
  }
}

@mixin requiredFieldLabelAsterisk {
  color: rgb(var(--colors-lan-green))
}

@mixin displayOnTableRowHover {
  opacity: 0;

  tr:hover & {
    opacity: 1;
  }
}
