@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 0 48px 0;
}

.buttons {
  @include drawerFormButtons();
}

.errorMessage {
  @include drawerFormError();
}

.deleteButton {
  margin-left: auto;
}
