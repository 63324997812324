.editFormWrapper {
    display: flex;
}

.emptyState {
    text-align: center;
    padding: 42px 0 64px 0;
    color: rgb(var(--colors-gray-6));
    font-size: 16px;
    max-width: 608px;
    margin: 0 auto;
}

.table {
    background: transparent;

    thead {
        color: rgb(var(--colors-gray-5));
    }

    th {
        padding: 16px;
    }

    td {
        color: rgb(var(--colors-gray-2));
        font-size: 14px;
        padding: 0;

        >div:not(.editFormWrapper) {
            padding: 16px;
            width: 100%;
            height: 100%;
        }

        &:first-of-type {
            padding-left: 0;

            >div:not(.editFormWrapper) {
                padding-left: 8px;
            }
        }

        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7) {
            padding: 0 2px 0 0;
        }
    }

    [class*='tbodyRow'] {
        border-top: 1px solid rgb(var(--colors-gray-7));

        &:hover+[class*='tbodyRow'] {
            border-top: 1px solid rgb(var(--colors-gray-7));
        }

        &:hover:not([class*='row__hover']) {
            background-color: initial;
            border-top: 1px solid rgb(var(--colors-gray-7));
        }

        &:first-of-type,
        &:first-of-type:not([class*='row__hover']):hover {
            border-top: 1px solid rgb(var(--colors-gray-5));
        }

        &:last-of-type:not(:hover),
        &:last-of-type:hover {
            border-bottom: 1px solid rgb(var(--colors-gray-7));
        }
    }

    [class*='tbodyRowExpanded'] {
        td {
            height: auto;
        }
    }
}