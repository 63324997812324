.scrollContainer {
  padding: 0 24px;
  margin: 0 -24px;
  overflow-x: auto;
}

.inner {
  border: none;
  border-radius: 8px;
  background-color: rgba(var(--colors-black), 0.75);
  padding: 16px 40px 32px;
}
