@import "../../../styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  background-color: rgb(var(--colors-gray-7));
  padding: 4px;
  border-radius: 30px;
  width: max-content;
}

.legend {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: rgb(var(--colors-white));
  font-family: 'HalenoirText', sans-serif;
  font-size: 14px;
  font-weight: 500;
  border-radius: 40px;
  padding: 8px 4px;

  &:active {
    background-color: rgb(var(--colors-gray-6));
    outline: none;
  }
}

.legendColor {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.segmentedControl {
  background-color: rgb(var(--colors-gray-6));
}

.dropdownWrapper {
  z-index: unset;
}

.dropdownContent {
  padding: 16px 24px;
}

.dropdownActions {
  display: flex;
  margin-top: 32px;
  word-break: initial;
  justify-content: right;
  gap: 16px;
}

// Light theme buttons
@include buttons-light-theme;

