.wrapper {
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.link {
  color: rgb(var(--colors-lantern-main-color));

  &:hover {
    text-decoration: underline;
  }
}

.tooltip {
  max-width: 440px;
  padding: 12px;
}
