.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 6px;
  border-radius: 40px;
  background: linear-gradient(
    90deg,
    rgba(var(--colors-lan-rose)) 0%,
    rgba(var(--colors-lan-green)) 100%
  );
}

.rect {
  position: relative;
  width: 76px;
  height: 100%;
}

.triangleWrap {
  position: absolute;
  width: 8px;
  height: 8px;
  top: -2px;
  left: calc(0% - 4px);
  right: 0;
  filter: drop-shadow(1px 0 1px rgba(var(--colors-black), 0.4));
}

.triangle {
  width: 8px;
  height: 8px;
  background: rgb(var(--colors-white));
  clip-path: polygon(100% 0, 0 0, 50% 100%);
}

.tooltip {
  max-width: 440px;
}
