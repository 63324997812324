.wrapper {
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  background-color: rgb(var(--colors-black-2));
  padding: 64px;
  margin-bottom: 80px;
  display: flex;
  gap: 54px;
  align-items: center;
  justify-content: center;
}

.information {
  max-width: 440px;
  font-family: 'HalenoirText';
  font-weight: 500;
  font-size: 18px;
}

.heading {
  font-family: 'Halenoir';
  font-size: 64px;
  font-weight: 600;
  line-height: 0.9;
  padding: 16px 0;
}

.email {
  color: rgb(var(--colors-lan-green));
}
