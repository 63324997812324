@import "../../../styles/mixins";

.wrapper {
  position: relative;
  z-index: 1;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, -20px);
  transition: all 0.3s ease;
  margin: 10px 0 0 0;
  background-color: rgb(var(--colors-white));
  padding: 24px 0;
  border-radius: 8px;
  color: rgb(var(--colors-black));

  &.isOpen {
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
  }
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
  padding: 0 24px;
}

.actions {
  display: flex;
  padding: 0 24px;
  margin: 16px 0;
  word-break: initial;
  justify-content: right;
  gap: 16px;
}

// Light theme buttons
@include buttons-light-theme;
