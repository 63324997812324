@import '../../../styles/config.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all .3s ease;
  border-radius: 38px;

  button {
    font-family: inherit;
  }

  input {
    width: 0;
    height: 100%;
    border: none;
    font-size: 16px;
    background-color: transparent;
    color: rgb(var(--colors-lantern-main-color));
    transition: all .3s ease;
    font-family: inherit;
    padding: 0;
    caret-color: rgb(var(--colors-lan-green));

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgb(var(--colors-lantern-gray4));
    }

    &.focused {
      width: 100%;
      color: rgb(var(--colors-black));
    }

    &:hover:not(:focus) {
      cursor: pointer;
    }

    @include media('>=medium') {
      width: 60%;
    }
  }

  &:hover, &.focused, &.active {
    .icon {
      background-color: rgba(var(--colors-white), .22);
    }

    .searchIcon {
      transform: scale(.75);
    }
  }

  &:hover {
    .icon {
      cursor: pointer;
    }
  }

  &:not(.focused) {
    .buttonClear,
    .buttonClose {
      display: none;
    }
  }

  &.active {
    border-radius: 8px 8px 0 0;
    transition: none;
  }

  &.focused {
    background-color: rgb(var(--colors-white));
  }
}

.focused {
  border-color: rgb(var(--colors-lantern-main-color));
}

.icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex: 0 0 48px;
  transition: background-color .3s ease;
  margin-right: 4px;
}

.buttonClear {
  font-size: 14px;
  line-height: 18px;
  color: rgb(var(--colors-gray-4));
  background: none;
  border: none;
  margin-right: 16px;
  cursor: pointer;
  padding: 0 8px;

  &:hover {
    color: rgb(var(--colors-black));
  }
}

.buttonClose {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    color: rgb(var(--colors-black));
  }
}

.searchIcon {
  color: rgb(var(--colors-white));
  transition: transform .3s ease;

  &.focused {
    color: rgb(var(--colors-gray-4));
  }

  svg {
    width: 26px;
    height: 26px;
  }
}
