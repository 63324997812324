@mixin reorderingPanel {
  position: absolute;
  width: 0;
  transition: all .3s ease;
  overflow: hidden;
  background-color: rgb(var(--colors-black));

  .panelInner {
    opacity: 0;
    transition: all .2s ease;
  }

  &.open {
    .panelInner {
      opacity: 1;
      transition-delay: .3s;
    }
  }
}
