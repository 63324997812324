@import "../../../styles/config.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0 26px;

  @include media(">=xlarge") {
    display: none;
  }
}

.navBurger {
  width: 24px;
  height: 24px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.navBurger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: rgb(var(--colors-white));
  border-radius: 2px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.navBurger span:nth-child(1) {
  top: 0px;
}

.navBurger span:nth-child(2),
.navBurger span:nth-child(3) {
  top: 9px;
}

.navBurger span:nth-child(4) {
  top: 18px;
}

.navBurger.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}

.navBurger.open span:nth-child(2) {
  transform: rotate(45deg);
}

.navBurger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.navBurger.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
