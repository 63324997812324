.cell {
  font-size: 14px;
}

.footerLabel {
  font-family: 'HalenoirText';
  color: rgb(var(--colors-white));
  font-size: 16px;
  font-weight: 400;

  & [class*='wrapper'] {
    font-family: 'HalenoirText';
  }
}
