.wrapper {
  width: 100%;
  transition: padding .3s ease;

  &.expanded {
    .expandButton {
      transform: rotate(180deg);
    }
  }

  &__primary {
    background-color: rgba(var(--colors-black), .75);
    border-radius: 8px;
    padding: 27px 40px;

    .title {
      font-size: 20px;
    }

    .expandButton {
      margin-left: auto;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .headerChildren {
      margin-left: 20px;
    }

    .content {
      &.expanded {
        margin: 40px 0 0 0;
      }
    }

    &.expanded {
      padding-bottom: 40px;

      .title {
        font-size: 28px;
        transition-delay: .1s;
      }
    }
  }

  &__secondary {
    .header {
      border-top: 1px solid rgb(var(--colors-gray-6));
      background-color: rgb(var(--colors-gray-8));
      padding: 12px;
    }

    .expandButton {
      order: 1;
      margin-right: 12px;
    }

    .title {
      order: 2;
    }
  }
}

.header {
  display: flex;
  align-items: baseline;
}

.title {
  font-family: 'Halenoir';
  transition: font-size .3s ease;
}

.subTitle {
  font-weight: 500;
  font-size: 14px;
  color: rgb(var(--colors-gray-4));
  margin-left: 12px;
}

.expandButton {
  align-self: flex-start;
  cursor: pointer;
  transition: transform .3s ease;
}

.content {
  transition: all .3s ease;
  overflow: hidden;
}
