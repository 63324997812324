@import '../../../styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  &.active {
    z-index: 2;
  }

  &__primary {
    .button {
      color: rgb(var(--colors-gray-7));

      &.active {
        background-color: rgb(var(--colors-gray-2));
        border-color: rgb(var(--colors-gray-2));
      }

      &.isPlaceholder {
        color: rgb(var(--colors-gray-4));
      }
    }
  }

  &__secondary {
    .button {
      color: rgb(var(--colors-white));

      &.active {
        color: rgb(var(--colors-black));
        background-color: rgb(var(--colors-white));
        border-color: rgb(var(--colors-white));
      }

      &.isPlaceholder {
        color: rgb(var(--colors-gray-5));
      }
    }
  }
}

.caret {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  margin-top: -8px;
  right: 16px;
  transition: transform 0.2s ease;
}

.inputLabel {
  font-size: 14px;
  margin-bottom: 8px;
  color: rgb(var(--colors-gray-5));
}

.asterisk {
  @include requiredFieldLabelAsterisk
}


.notVisible {
  display: none;
}

.inner {
  background: none;
  padding: 3px 34px 3px 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  cursor: pointer;
  min-height: 38px;
  border-radius: 20px;
  border: 1px solid rgb(var(--colors-gray-6));
  position: relative;
  z-index: 1;
  color: rgb(var(--colors-lantern-main-color));
  user-select: none;

  &.disabled {
    pointer-events: none;
    cursor: default;
    background-color: rgb(var(--colors-gray-6));
    color: rgb(var(--colors-gray-4));
  }

  @include dropdown-controller-active;
}

.input {
  @include transparentInput;
}

.placeholder {
  font-size: 14px;
  color: rgb(var(--colors-gray-5));
}

.selectInvalid {
  border: 1px solid rgb(var(--colors-red));
  padding-right: 64px;

  .icon__danger {
    color: rgb(var(--colors-red));
    display: block;
  }

  .errorMessage {
    display: block;
  }
}

.errorMessage {
  @include selectErrorMessage;
}

.icon {
  position: absolute;
  top: 10px;
  right: 40px;
  display: none;
}

.group {
  &:not(:last-child) {

    &:after {
      content: '';
      display: block;
      margin: 12px 16px;
      border-bottom: 1px solid rgb(var(--colors-gray-2));
    }
  }
}
