.wrapper {
  margin-top: 24px;
  margin-bottom: 32px;
}

.column {
  display: flex;
  flex-direction: row;
  gap: 60px;
  padding: 16px 16px 16px 0;
}

.sectionTitle {
  font-size: 14px;
  color: rgb(var(--colors-gray-4));
}
.commentary {
  font-size: 16px;
  margin-inline-end: 80px;
}
.richTextOutput {
  ul {
    margin-left: 20px;
    li {
      list-style-type: disc;
    }
  }
}
