.transactionWrapper {
  width: 100%;
  padding-bottom: 50px;

  .transactionFormWrapper {
    background-color: rgba(var(--colors-black), 0.75);
    padding-bottom: 40px;
    padding-top: 16px;

    .formHeaderWrapper {
      padding: 16px 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .buttonsWrapper {
        display: flex;
        justify-content: flex-end;

        & div {
          margin-top: 0;

          .main {
            margin-left: 16px;
          }
        }
      }

      .transactionCount {
        font-size: 22px;
        font-style: normal;
        font-weight: 400;

        .rowsCount {
          margin-left: 16px;
          font-size: 20px;
          padding: 4px 8px;
          border-radius: 6px;
          background: rgb(var(--colors-gray-7));
        }
      }
    }

    .formWrapper {
      // width: 100%;
      margin: 0 40px;
      padding: 16px 0 32px;
      border-radius: 4px;
      background: var(--nearcasting-gradient);

      .header {
        margin-top: 24px;
        font-size: 24px;
        padding: 0 40px;
      }

      .formInputRowWrapper {
        padding: 32px 40px;
        border-radius: 8px;

        .formInputRow {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 16px;
          justify-content: flex-start;

          .formInputWrapper {
            width: calc(25% - 16px);
            height: 66px;

            .formInput {
              color: rgb(var(--colors-white));

              .dateInput {
                &> :nth-child(1) {
                  button {
                    display: flex;
                    width: 100%;
                    height: 40px;
                    padding: 11px 50px 11px 24px;
                    border-radius: 30px;
                  }
                }
              }

              .formInputLabel {
                font-size: 14px;
                margin-bottom: 8px;
                color: rgb(var(--colors-gray-5));
              }

              &>div>label {
                color: rgb(var(--colors-gray-3));
              }
            }
          }
        }

        .description {
          margin-top: 32px;

          .descriptionInput {
            height: 115px;
            color: rgb(var(--colors-white));
          }
        }
      }

      .buttonsWrapper {
        display: flex;
        justify-content: flex-end;
        padding: 0 40px;
        gap: 16px;
      }
    }

    .asterisk {
      color: rgb(var(--colors-lan-green));
    }
  }

  .formHeaderTitle {
    // margin: 24px 0 0 24px;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 30px;
  }
}

.table {
  background: transparent;
}