.wrapper {
  background-color: rgba(var(--colors-black), 0.75);
  border-radius: 8px;
  padding: 32px 40px;
  width: 100%;
  height: 100%;
  position: relative;
}

.title {
  font-size: 20px;
  line-height: 26px;
  margin: 0 0 40px 0;
  font-family: 'Halenoir';
}

.chart {
  position: relative;
  width: 140px;
  flex: 0 0 140px;
  margin: 0 40px 0 0;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 100%;
  }
}

.chartInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
}

.placeholderLegend {
  font-size: 14px;
  color: rgb(var(--colors-gray-4));
  align-self: center;

  &:empty {
    display: none;
  }
}

.editButton {
  position: absolute;
  top: 20px;
  right: 20px;
}
