.wrapper {
  font-size: 20px;
}

.expandButton {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  display: inline-flex;
  align-items: flex-end;
  gap: 4px;
  cursor: pointer;
}
