@import 'src/styles/mixins';

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .spinner {
    color: rgb(var(--colors-gray-6));
    svg {
      width: 48px;
      height: 48px;
    }
  }
}

.socialMedia {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 12px;
  align-items: center;
}

.socialMediaIcon svg {
  width: 24px;
  height: 24px;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgb(var(--colors-gray-7));

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.groupTitle {
  font-weight: 500;
  font-size: 16px;
  color: rgb(var(--colors-gray-3));
  margin-bottom: 12px;
}

.dateInputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.buttons {
  @include drawerFormButtons();
}

.errorMessage {
  @include drawerFormError();
}
