@import "../../../../../styles/config";

.title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
  font-weight: 400;
}

.wrapper {
  padding: 0 0 32px;
  border-bottom: 1px solid rgb(var(--colors-gray-7));
}

.table {
  display: table;
  flex-wrap: wrap;
}

.row {
  padding: 24px 10px 8px 0;
  font-weight: 400;
  display: table-row;
  padding: 0;

  &:first-child {
    display: table-row;
  }

  &:nth-child(even) {
    flex: 1 1 55%;
    .cell {
      display: table-cell;
      padding: 0 16px 16px 0;
      align-items: baseline;
    }
  }
}

.head,
.cell {
  display: table-cell;
  width: 33%;
  padding: 0 16px 16px 0;

  &:nth-child(2) {
    width: 50%;
  }
}

.head {
  display: table-cell;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgb(var(--colors-gray-4));
}

.cell {
  font-size: 16px;
}
