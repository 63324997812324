.wrapper {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  gap: 26px 16px;
}

.currencySelect {
  max-width: 180px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  grid-column-start: 2;
  grid-column-end: 2;
}
