@import "../../../../../styles/config";

.cell {
  margin: 0;
  padding: 32px 12px 16px 0;
  color: rgb(var(--colors-white));
  background-color: rgba(var(--colors-black), .75);
  font-size: 22px;
  font-weight: 400;
  text-align: left;
}

.groupHeaderCellSpan {
  margin: 0;
  padding: 19px 12px 19px 0;
  color: rgb(var(--colors-gray-4));
  font-size: 12px;
  font-weight: 500;
}

.notVisible {
  display: none;
}
