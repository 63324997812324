.wrapper {
  min-height: 300px;
  position: relative;
  padding: 32px 0;
}

.content {
  padding: 40px 0;
}

.firstRow {
  background-color: transparent;
  width: 522px;
  padding: 0 28px;
}

.secondRow {
  background-color: transparent;
  width: 628px;
  padding: 0 28px;
}

.expanderSpinner {
  position: absolute;
  top: 30%;
}
