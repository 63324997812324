.label {
  font-size: 16px;
  margin-bottom: 16px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
