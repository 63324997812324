@import '../../../styles/config';
@import '../../../styles/grids';

.wrapper {
  padding-bottom: 50px;
}

.sectionHeader {
  margin: 100px 0 20px 0;
}

.secondarySectionHeader {
  margin: 0 0 20px 0;
}

.companiesSection {
  @extend %companiesSection;
}

.chartPlaceholder {
  position: relative;
  min-height: 430px;
  background-color: rgb(var(--colors-black));
  color: rgb(var(--colors-white));
}
