@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
}

.buttons {
  @include drawerFormButtons();
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .spinner {
    color: rgb(var(--colors-gray-6));

    svg {
      width: 48px;
      height: 48px;
    }
  }
}

.group {
  padding: 24px 0 0 0;
  border-top: 1px solid rgb(var(--colors-gray-7));
}

.groupTitle {
  font-size: 20px;
  margin-bottom: 16px;
}

.groupSubTitle {
  font-size: 14px;
  font-weight: 400;
  color: rgb(var(--colors-gray-4));
  margin-left: 8px;
}

.groupMetrics {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.errorMessage {
  @include drawerFormError();
}
