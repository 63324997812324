.wrapper {
  height: 1px;
  flex: 1 1 auto;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgb(var(--colors-gray-6));
    top: -4px;
    left: -4px;
  }

  &.scaled {
    flex: 1 1 20%;

    & + .wrapper:before,
    &:before {
      width: 13px;
      height: 13px;
      top: -7px;
      left: -7px;
    }
  }
}

.label {
  display: block;
  font-size: 14px;
  line-height: 18px;
  position: absolute;
  top: 16px;
  right: 100%;
  transform: translate(50%, 0);
}

.quarterPoint {
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: rgb(var(--colors-gray-6));
  top: -4px;
  left: -4px;
}

.todayPoint {
  position: absolute;
  background-color: rgb(var(--colors-lan-green));
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: -4px;
  margin-left: -3px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 0;
    bottom: 100%;
    left: 3px;
    background-color: rgb(var(--colors-lan-green));
    opacity: 0;
  }

  span {
    font-weight: 500;
    position: absolute;
    font-size: 12px;
    line-height: 16px;
    visibility: hidden;
    color: rgb(var(--colors-gray-3));
    white-space: nowrap;
    opacity: 0;
    top: 0;
    margin-left: 4px;
    left: 100%;
  }

  &.expanded {

    span {
      visibility: visible;
      opacity: 1;
      transition: .3s opacity .3s ease;
    }

    &:before {
      opacity: 1;
      height: 203px;
      transition: all .3s ease;
    }

    &.primary {

      span {
        top: -189px;
      }

      &:before {
        height: 203px;
      }
    }

    &.secondary {

      span {
        top: -312px;
      }

      &:before {
        height: 326px;
      }
    }
  }
}
