.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 6px;
  font-size: 16px;
  min-height: 430px;
  background-color: rgb(var(--colors-black));
  color: rgb(var(--colors-white));
}

.title {
  font-size: 22px;
}

.icon {
  margin-bottom: 16px;

  svg {
    width: 40px;
    height: 40px;
  }
}
