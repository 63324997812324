@import '../../../styles/config.scss';
@import '../../../styles/mixins.scss';

.navMenu {
  position: fixed;
  top: 16px;
  left: 20px;
  width: 85px;
  //height: 865px;
  min-height: 60px;
  display: none;
  flex-direction: column;
  border-radius: 8px;
  font-size: 12px;
  background: linear-gradient(
                  180deg,
                  rgb(var(--colors-lantern-dark)) 0%,
                  rgba(var(--colors-lantern-dark), 0.22) 100%
  );
  transition: top 0.3s ease;
  overflow: hidden;

  hr {
    margin: 0 10px 15px 10px;
    border: 1px solid rgba(var(--colors-gray-7));
  }

  span {
    padding-top: 10px;
  }

  @include media('>=xlarge') {
    display: block;
  }

  &.sticky {
    top: 104px;
    padding-top: 30px;

    .listItem:first-child {
      display: none;
    }

    hr:first-of-type {
      display: none;
    }
  }
}

.listItem {
  margin-bottom: 20px;
}

.listIcon {

  svg {
    width: 24px;
    height: 24px;
  }
}

.listItem:first-child {
  margin-top: 20px;
}

.active {
  position: relative;
  background-color: rgb(var(--colors-gray-8));

  &::after {
    @extend #afterContent;
  }

  .listIcon {
    color: rgb(var(--colors-lan-green));
  }

  span {
    color: rgb(var(--colors-lan-green));
  }
}

.link {
  text-decoration: none;
  color: #cfcfcf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 0;

  @include disabled-nav-menu-link;
}

.navMenu:hover {
  .listItem:not(:first-child) {
    .active {
      background-color: revert;

      &::after {
        display: none;
      }
    }

    .link:hover {
      position: relative;
      background-color: rgb(var(--colors-gray-8));

      &::after {
        @extend #afterContent;
      }
    }
  }
}

#afterContent {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: rgb(var(--colors-lan-green));
}

.logo {
  width: 30px;
  height: 32px;
}
