.legend {
  display: flex;
  align-items: center;
}

.legendItem {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: rgb(var(--colors-gray-3));

  &:not(:first-child) {
    margin-left: 24px;
  }
}

.legendBullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 6px;
}