.wrapper {
  padding: 40px;
  background-color: rgb(var(--colors-black));
  display: grid;
  grid-template-columns: 200px auto;
}

.info {
  border-right: 1px solid rgb(var(--colors-gray-7));
  padding-right: 16px;
}

.infoTitle {
  color: rgb(var(--colors-gray-4));
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.infoValue {
  font-size: 20px;
  margin-bottom: 8px;
  word-break: break-word;
}

.infoSubValue {
  text-decoration: underline;
  font-size: 12px;
  color: rgb(var(--colors-gray-4));
  word-break: break-word;
}

.infoDate {
  font-size: 12px;
  color: rgb(var(--colors-gray-4));
}

.separator {
  margin-bottom: 40px;
}

.timeline {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 100px 0;
}

.timelineItem {
  flex: 1 1 auto;
  position: relative;
  border-top: 1px solid rgb(var(--colors-gray-6));

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(var(--colors-lantern-main-color));
    top: -6px;
    right: -6px;
  }

  &:first-child {
    flex: 0 0 1px;
    border: none;
  }

  &.current {
    &:before {
      width: 14px;
      height: 14px;
      top: -7px;
      right: -7px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid rgb(var(--colors-lantern-main-color));
      top: -12px;
      right: -12px;
    }
  }

  &.current ~ & {
    border-top-color: rgb(var(--colors-gray-6));
    border-top-style: dashed;
    color: rgb(var(--colors-gray-4));

    &:before {
      background-color: rgb(var(--colors-gray-6));
    }
  }
}

.timelineDate {
  position: absolute;
  z-index: 2;
  font-size: 14px;
  font-weight: 500;
  color: rgb(var(--colors-gray-4));
  top: -50px;
  right: 0;
  transform: translateX(50%);
  white-space: nowrap;
}

.timelineStatus {
  position: absolute;
  z-index: 1;
  top: 30px;
  right: -60px;
  width: 120px;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}

.timelineStatusLabel {
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
}
