@import 'src/styles/config';

.wrapper {
  padding: 0 0 0 10px;

  &:first-child {
    padding: 0;
  }
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgb(var(--colors-gray-4));
  margin-bottom: 8px;
}

.value {
  font-size: 20px;
  line-height: 32px;

  sub {
    font-size: 14px;
  }
}

.currency {
  font-size: 14px;
  line-height: 18px;
  color: rgb(var(--colors-gray-4));
  text-transform: uppercase;
  margin-right: 4px;
}
