.tooltipWrapper {
  background: rgb(var(--colors-gray-7));
  border-radius: 8px;
  padding: 12px;
}

.quarter {
  font-size: 14px;
  padding-bottom: 8px;
  color: rgb(var(--colors-lantern-main-color));
  font-weight: 400;
  border-bottom: 1px solid rgb(var(--colors-gray-6));
}

.value {
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 14px;
  padding-top: 8px;
  color: rgb(var(--colors-lantern-main-color));
  font-weight: 400;

  span {
    font-weight: 600;
  }
}

.icon {
  color: rgb(var(--colors-gray-5));
}
