.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  &.inActive {
    filter: grayscale(1);

    .name {
      color: rgb(var(--colors-gray-4));
    }

    .logo {
      color: rgb(var(--colors-gray-4));
    }
  }
}

.logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name {
  font-weight: 500;
  color: rgb(var(--colors-lantern-main-color));
  margin-bottom: 4px;
}

.role {
  font-size: 12px;
  color: rgb(var(--colors-gray-4));
}
