@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;

  // Fix made as a part of LP-2989 to prevent the Dropdown Wrapper z-index issues on Manage Workflow Form - Aleksa Jukic
  .dropdownWrapper {
    z-index: unset;

    [data-dropdown-wrapper-dropdown] {
      z-index: 2;
    }
  }
}

.buttons {
  @include drawerFormButtons();
}

.stopWorkflowButton {
  margin-left: auto;
}

.statusLabel {
  color: rgb(var(--colors-gray-4));
  font-size: 14px;
  margin-bottom: 8px;
}

.statusDate {
  color: rgb(var(--colors-gray-4));
  font-size: 12px;
  margin-top: 4px;
  padding-left: 16px;
}

.errorMessage {
  @include drawerFormError();
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .spinner {
    color: rgb(var(--colors-gray-6));

    svg {
      width: 48px;
      height: 48px;
    }
  }
}

.dropdownWrapper {
  position: relative;
  z-index: 2;
}

.assigneeInputWrapper {
  width: 100%;
  z-index: 1;
}

.assigneeOptions {
  background-color: rgb(var(--colors-lantern-main-color));
  border-radius: 8px;
  width: 100%;
  padding: 8px 0;
}

.assigneeOption {
  display: block;
  padding: 8px 40px;
  color: rgb(var(--colors-black));
  cursor: pointer;
  font-weight: 400;

  &.selected {
    background-color: rgb(var(--colors-gray-2));
  }

  &:hover {
    background-color: rgb(var(--colors-gray-1));
  }
}

// Workflow Type Tooltip Styles - Aleksa Jukic
.workflowTooltipWrapper {
  padding: 12px;
  max-width: 360px;
}

.workflowTooltipCollectionInfo {
  margin-top: 24px;
}

.workflowTooltipSeparator {
  height: 1px;
  margin: 16px 0;
  background: rgb(var(--colors-gray-6));
}

.workflowTooltipHeading {
  display: block;
  margin-bottom: 4px;
}
