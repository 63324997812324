@import "../../../styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 50px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 11px 24px;
  font-weight: 500;
  font-size: 14px;
  font-family: inherit;

  &:disabled {
    pointer-events: none;
    background-color: rgb(var(--colors-gray-4));
    color: rgb(var(--colors-gray-6));
  }

  &__primary {
    background-color: rgb(var(--colors-lantern-main-color));
    color: rgb(var(--colors-black));

    &:hover, &.loading {
      background-color: rgb(var(--colors-gray-3));
      color: rgb(var(--colors-gray-7));
      outline: none;
    }
  }

  &__secondary {
    background-color: transparent;
    color: rgb(var(--colors-lantern-main-color));
    border: 1px solid rgb(var(--colors-lantern-main-color));

    &:hover, &.loading {
      border: 1px solid rgb(var(--colors-gray-3));
      color: rgb(var(--colors-gray-3));
      outline: none;
    }

    &:disabled {
      border: 1px solid rgb(var(--colors-gray-6));
      color: rgb(var(--colors-gray-6));
      background-color: transparent;
    }
  }

  &__confirmative {
    background-color: rgb(var(--colors-lan-green));
    color: rgb(var(--colors-black));

    &:hover, &.loading {
      background-color: rgb(var(--colors-green-haze));
      outline: none;
    }
  }

  &__warning {
    background-color: rgb(var(--colors-red));
    color: rgb(var(--colors-lantern-main-color));

    &:hover, &.loading {
      background-color: rgb(var(--colors-sangria));
      outline: none;
    }
  }

  @include button-loading;
}

.small {
  padding: 6px 16px;
}

.medium {
  padding: 10px 24px;
}

.large {
  padding: 13px 24px;
  font-size: 16px;
}

.wrapper svg {
  width: 16px;
  height: 16px;
}
