@import '../../../../../styles/config.scss';

.tabsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__primary {
    .tab {
      flex: 1 1 auto;
    }

    .activeTab {
      &:before {
        border-bottom-color: rgb(var(--colors-lantern-main-color));
      }
    }
  }

  &__secondary {
    background-color: rgba(var(--colors-black), 0.74);
    border-radius: 8px 8px 0 0;

    .tab {
      flex: 1 1 30%;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;

      &:not(:first-child):not(:hover):after {
        content: '';
        display: block;
        position: absolute;
        left: -2px;
        height: 85px;
        width: 2px;
        top: 15px;
        background-color: rgb(var(--colors-gray-8));
      }

      &:hover {
        background-color: rgb(var(--colors-gray-8));

        & + .activeTab {
          border-left: 2px solid rgb(var(--colors-black));
        }

        & + .tab:after {
          display: none;
          position: static;
        }
      }
    }

    .activeTab {
      background-color: rgb(var(--colors-gray-8));

      &:before {
        border-bottom-color: rgb(var(--colors-lan-green));
      }

      & + .tab:hover {
        border-left-color: rgb(var(--colors-black));
      }

      & ~ .tab:not(:first-child):not(:hover):after {
        left: -2px;
      }

      & + .tab:not(:hover):after {
        display: none;
        position: static;
      }
    }
  }
}

.tab {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding: 0 10px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 2px;
    left: -2px;
    right: -2px;
    border-bottom: 2px solid rgb(var(--colors-gray-6));
  }
}

.tabName {
  font-size: 14px;
  color: rgb(var(--colors-gray-4));
  font-weight: 500;
  transition: all .3s ease;
}

.tabValue {
  font-size: 18px;
  color: rgb(var(--colors-lantern-main-color));
  transition: all .3s ease;
  white-space: nowrap;

  sub {
    font-size: 12px;
  }

  @include media('>=xlarge') {
    font-size: 20px;

    sub {
      font-size: 14px;
    }
  }

  span {
    font-size: 14px;
    font-weight: 400;

    @include media('>=xxlarge') {
      font-size: 16px;
    }
  }
}

.activeTab {
  &:before {
    border-bottom-color: rgb(var(--colors-lan-green));
  }

  .tabName {
    font-size: 16px;
    font-weight: 600;

    @include media('>=xlarge') {
      font-size: 18px;
    }
  }

  .tabValue {
    font-size: 24px;

    sub {
      font-size: 18px;
    }

    @include media('>=xlarge') {
      font-size: 32px;

      sub {
        font-size: 20px;
      }
    }

    @include media('>=xxlarge') {
      font-size: 32px;

      sub {
        font-size: 22px;
      }
    }

    span {
      font-size: 16px;
    }
  }
}

.tabNameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabNameRow {
  display: flex;
}

.tabNameValueWrapper {
  display: flex;
  align-items: baseline;
}

.row {
  display: flex;
  align-items: flex-end;
}

.kpiWrapper {
  margin-left: 12px;

  @include media('>=xlarge') {
    margin-left: 24px;
  }
}
