@import '../../../../styles/mixins';

.cell {
  font-size: 14px;
  word-break: break-word;
}

.textTruncation {
  font-size: 14px;
}

.edit {
  @include displayOnTableRowHover;
}
