@import '../../../../../../../styles/config.scss';

.deltaWrapper {
  margin-left: 8px;
  display: flex;
  align-items: center;

  @include media('>=medium') {
    display: block;
    line-height: 28px;
  }

  div {
    margin-left: 6px;

    @include media('>=medium') {
      margin-left: 0;
    }
  }
}

.deltaValue {
  font-size: 12px;
  color: rgb(var(--colors-gray-4));

  @include media('>=xlarge') {
    font-size: 14px;
  }
}

.icon {
  display: block;

  svg {
    width: 12px;
    height: 12px;
  }

  @include media('>=medium') {
    margin-bottom: 0px;
  }
}
