.wrapper {
  background-color: rgb(var(--colors-lantern-dark));
  border-radius: 8px 0 0 8px;

  &.collapsed {
    .accordionsWrapper {
      opacity: 0;
      visibility: hidden;
      white-space: nowrap;
      transition-delay: 0s;
    }

    .top {
      padding: 28px 0;
    }

    .title {
      margin: 0 auto;
    }

    .expandButton {
      right: 50%;
      transform: translateX(50%) rotate(180deg);
    }

    .topGroupLabel {
      font-size: 0;
      line-height: 0;
      width: 0;
      opacity: 0;
      margin: 0;
    }
  }
}

.top {
  display: flex;
  align-items: center;
  padding: 28px 12px 28px 40px;
  font-size: 12px;
  color: rgb(var(--colors-gray-4));
  position: relative;
  z-index: 1;
}

.expandButton {
  position: absolute;
  bottom: -20px;
  right: -20px;
}

.title {
  margin-right: auto;
}

.topGroupLabel {
  margin-left: 16px;
  transition: opacity .2s ease;
  transition-delay: .2s;
}

.metricItem {
  display: flex;
  padding: 12px 12px 12px 40px;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid rgb(var(--colors-gray-7));

  &:last-child {
    border-bottom: none;
  }
}

.metricLabel {
  margin-right: auto;
}

.checkboxWrapper {
  margin-left: 16px;
  color: transparent;
}

.checkbox {
  width: 16px;
  margin: 0 auto;
}

.checkboxLabel {
  font-size: 12px;
  line-height: 0;
  user-select: none;
  pointer-events: none;
}

.accordionsWrapper {
  overflow: hidden;
  transition: opacity .3s ease;
  transition-delay: .3s;
}
