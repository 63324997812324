@import '../../../styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__primary {
    .input {
      color: rgb(var(--colors-gray-7));
    }

    .autoCompleteInput {
      color: rgba(var(--colors-gray-7), 5);
    }
  }

  &__secondary {
    .input {
      color: rgb(var(--colors-white));
    }

    .autoCompleteInput {
      color: rgba(var(--colors-white), .5);
    }
  }
}

.inputField {
  position: relative;
}

.autoCompleteInput {
  position: absolute;
  border: none;
  display: block;
  user-select: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  background: none;
  z-index: 1;
  top: 12px;
  left: 25px;
  right: 50px;
}

.input {
  padding: 11px 50px 11px 24px;
  border-radius: 30px;
  border: 1px solid rgb(var(--colors-gray-5));
  font-size: 14px;
  font-weight: 500;
  font-family: 'HalenoirText';
  width: 100%;
  background: none;
  position: relative;
  z-index: 1;

  &:focus {
    outline: none;
    border-color: rgb(var(--colors-lan-green));
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.inputLabel {
  display: flex;
  font-size: 14px;
  margin-bottom: 8px;
  color: rgb(var(--colors-gray-5));
}

.errorMessage {
  position: absolute;
  z-index: 1;
  bottom: 36px;
  right: 18px;
  background: rgb(var(--colors-red));
  font-size: 12px;
  padding: 8px;
  color: rgb(var(--colors-white));
  border-radius: 6px;
  white-space: normal;
  display: none;

  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: rgb(var(--colors-red));
    position: absolute;
    bottom: -4px;
    right: 10px;
    transform: rotate(45deg);
  }
}

.inputInvalid {
  padding-right: 50px;
  border: 1px solid rgb(var(--colors-red));

  ~ .icon__danger {
    color: rgb(var(--colors-red));
    display: block;
  }

  ~ .errorMessage {
    display: block;
  }
}

.inputValid {
  padding-right: 50px;

  ~ .icon__success {
    color: rgb(var(--colors-limeade));
    display: block;
  }
}

.input:disabled {
  color: rgb(var(--colors-gray-4));
  background: rgb(var(--colors-gray-7));
}

.input::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: rgb(var(--colors-gray-4));
}

.icon {
  position: absolute;
  top: 12px;
  right: 24px;
  display: none;
}

.asterisk {
  @include requiredFieldLabelAsterisk
}
