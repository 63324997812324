.wrapper {
  border-radius: 8px;
  padding: 24px 0 0;
  border: 1px solid transparent;

  &__dragEntered {
    border-color: rgb(var(--colors-lan-green));
    background-color: rgb(var(--colors-gray-7));

    .inner,
    .top,
    .footer {
      pointer-events: none;
    }

    .inner > div {
      background-color: rgb(var(--colors-gray-7));

      thead {
        background: transparent;
      }
    }
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 40px;
  gap: 12px;
}

.uploadBlock {
  flex: 0 0 660px;
  border: 1px dashed rgb(var(--colors-gray-6));
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: rgb(var(--colors-gray-5));
  padding: 12px;
  margin-left: auto;
}

.input {
  display: none;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 40px 0 32px;
  position: sticky;
  bottom: 30px;
}

.dragHint {
  background-color: rgb(var(--colors-lan-green));
  border-radius: 8px;
  padding: 25px 32px;
  color: rgb(var(--colors-black));
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  svg {
    width: 24px;
    height: 24px;
  }
}
