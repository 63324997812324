.actionButton {
  padding: 0px;
}

.expander {
  margin-top: 0;
  color: rgb(var(--colors-gray-6));
}

.expanderExpanded {
  transform: rotate(180deg);
}

.nameCell {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.nameCellContent {
  display: flex;
  align-items: center;

  &>div:first-child {
    margin-right: 8px;
  }
}

.nameCellWrapper {
  cursor: pointer;

  &:hover {
    background-color: rgb(var(--colors-gray-6));
  }
}

.nameCellWrapperNc {
  &:hover {
    background: var(--nearcasting-gradient-medium);
  }
}

.isCasting {
  background-color: rgb(var(--colors-red));
}

.nearCastCostCell {
  background-color: rgb(var(--colors-gray-8), 0.4);
}

.nearCastCostCellNc {
  background: var(--nearcasting-gradient);
}

.editableCell {
  cursor: pointer;
  background-color: rgb(var(--colors-gray-8));
  border: 1px solid transparent;

  &:hover {
    background-color: rgb(var(--colors-gray-6));
  }
}

.editableCellNc {
  background: var(--nearcasting-gradient-medium) -1px;
  background-size: 103%;


  &:hover {
    background: var(--nearcasting-gradient) -1px;
    background-size: 103%;
  }
}

.dotsMenu {
  background-color: rgb(var(--colors-gray-8), 0.4);
}

.dotsMenuNc {
  background: var(--nearcasting-gradient-medium);
}