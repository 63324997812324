.wrapper {
  background-color: rgb(var(--colors-white), 0.04);
}

.content {
  padding: 0 8px;
  margin-bottom: -16px;
}

.table {
  background: transparent;
  padding: 0px;

  tr td {
    font-size: 12px;
  }

  tr[class*=tbodyRow]:last-of-type:not(:hover),
  tr[class*=tbodyRow]:last-of-type:hover {
    border-bottom: none;
  }
}

.emptyState {
  text-align: center;
  padding: 42px 0 64px 0;
  color: rgb(var(--colors-gray-6));
  font-size: 16px;
  max-width: 618px;
  margin: 0 auto;
}


.header {
  padding: 24px 0;
}

.addTransactionForm {
  border-radius: 2px;
  padding: 24px 0;
  background: var(--nearcasting-gradient);
}