@import '../../../styles/config';

.wrapper {
  position: relative;
  margin: 0 0 37px;
  display: flex;
  align-items: flex-start;

  @include media('>=medium') {
    margin: 0 0 80px;
  }
}

.logo {
  margin-right: 8px;
  margin-left: 20px;

  @include media('>=medium') {
    margin-right: 16px;
    margin-top: 8px;
    margin-left: 0;
  }

  @include media('>=large') {
    margin-right: 24px;
    margin-top: 6px;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  font-family: 'Halenoir';

  @include media('>=medium') {
    font-size: 36px;
    line-height: 56px;
  }

  @include media('>=large') {
    font-size: 44px;
    line-height: 56px;
  }
}

.subTitle {
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
  height: 24px;

  @include media('>=large') {
    font-size: 18px;
    line-height: 24px;
  }
}

.colorLine {
  width: 4px;
  height: 25px;
  margin-right: 16px;
  margin-top: 3px;

  @include media('>=medium') {
    margin-right: 24px;
    height: 31px;
    margin-top: 11px;
  }

  @include media('>=large') {
    margin-top: 13px;
  }
}

.titleCounter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 45px;
  font-size: 24px;
  font-weight: 400;
  border-radius: 6px;
  background: rgba(var(--colors-white), 0.12);
  margin-left: 24px;
  padding: 8px;
}
