@import 'src/styles/config';

.wrapper {
  padding: 0 0 0 10px;

  &:first-child {
    padding: 0;
  }
}

.label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgb(var(--colors-gray-4));
  margin-bottom: 8px;

  @include media('>=small') {
    font-size: 14px;
    line-height: 18px;
  }
}

.value {
  font-size: 24px;
  line-height: 32px;

  sub {
    font-size: 16px;
  }

  @include media('>=small') {
    font-size: 20px;
    line-height: 40px;

    sub {
      font-size: 14px;
    }
  }
}

.currency {
  font-size: 12px;
  line-height: 16px;
  color: rgb(var(--colors-gray-4));
  text-transform: uppercase;
  margin-right: 4px;

  @include media('>=small') {
    font-size: 14px;
    line-height: 18px;
  }
}
