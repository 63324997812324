.wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  align-items: center;
}

.instrumentIcon {
  margin-left: 4px;
}
