@import '../../../styles/config';
@import '../../../styles/reorderingPanel';

.wrapper {
  margin: 60px 0 80px 0;
  position: relative;
}

.insightsHeader {
  position: relative;
  z-index: 2;
  margin-bottom: 12px;
}

.panel {
  @include reorderingPanel();
  top: 52px;
  right: -24px;

  &.open {
    width: calc(33% + 14px);
  }

  @include media('>=xlarge') {
    right: -32px;

    &.open {
      width: calc(33% + 22px);
    }
  }
}

.insightsSection {
  position: relative;
  z-index: 1;
  display: grid;
  // replace once mobile view is available
  // grid-template-columns: minmax(0, 1fr);
  grid-template-columns: repeat(2, calc(50% - 10px));
  gap: 16px;
  width: 100%;
  transition: all .3s ease;

  &.open {
    width: 66%;
  }

  @include media('>large') {
    grid-template-columns: repeat(2, calc(50% - 10px));

    &.open {
      grid-template-columns: repeat(1, calc(100%));
    }
  }

  @include media('>=xlarge') {
    grid-template-columns: repeat(3, calc(33% - 7px));

    &.open {
      grid-template-columns: repeat(2, calc(50% - 10px));
    }
  }
}

.insightsWidget {
  width: 100%;
  margin-bottom: 16px;

  @include media('>large') {
    margin-bottom: 0;
  }
}
