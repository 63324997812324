@import '../../../styles/mixins';
@import '../../../styles/config';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: rgba(var(--colors-black), 0.3);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  box-shadow: 0 0 10px rgba(var(--colors-black));

  &__primary {
    background: rgb(var(--colors-white));
    border-radius: 8px;
    width: 632px;
    color: rgb(var(--colors-black));
    position: relative;
  }

  &__secondary {
    position: absolute;
    top: 0;
    right: 0;
    width: 448px;
    height: 100vh;
    background-color: rgb(var(--colors-black));
    transform: translateX(100%);
    transition: transform 0.3s ease;

    &.animated {
      transform: translateX(0);
    }

    .header {
      padding: 32px 30px 32px 0;
      margin: 0 24px;
      border-bottom: 1px solid rgb(var(--colors-gray-7));
    }

    .footer {
      padding: 16px 0;
      margin: 0 24px;
      border-top: 1px solid rgb(var(--colors-gray-7));
      background-color: rgb(var(--colors-black));
    }

    .content {
      flex: 1 1 auto;
      overflow: auto;
      margin: 0 8px 0 24px;
      padding: 32px 10px 60px 0;

      @include scrollBarStyles;
    }

    &.sidePanelDialogOpen {
      pointer-events: none;
    }
  }
}

.header {
  padding: 32px 54px 24px 24px;
  font-weight: 500;
  font-size: 22px;
}

.footer {
  padding: 16px;
  border-top: 1px solid rgb(var(--colors-gray-2));
}

.title {
  font-family: 'Halenoir';
  word-break: break-word;
}

.subTitle {
  font-size: 14px;
  margin-top: 8px;
  font-weight: 400;
}

.closeButton {
  position: absolute;
  top: 26px;
  right: 16px;
}

.content {
  flex: 1 1 auto;
}

.sidePanelDialog {
  position: absolute;
  right: 456px;
  margin: auto 0;
  width: calc(100% - 456px - 24px);

  @include media('>=xlarge') {
    width: calc(100% - 456px - 128px);
  }
}

.fullViewport {
  width: 100%;

  .content {
    width: 100%;
    height: 100%;
  }
}
