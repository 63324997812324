.wrapper {
  width: 100%;
  margin: 80px 0;
}

.sectionHeader {
  margin-bottom: 16px;
}

.placeholderWrapper {
  background-color: rgb(var(--colors-black));
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  border-radius: 8px;
}
