.wrapper {
  margin-top: 80px;
}

.exposuresSectionHeader {
  margin-bottom: 16px;
}
.exposuresInfoHeader {
  margin-bottom: 32px;
}
