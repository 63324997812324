@import '../../../styles/mixins';

.modalWrapper {
  background: rgb(var(--colors-white));
  color: rgb(var(--colors-black));
  width: 600px;
  padding: 8px 16px;
  transition: all .3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.templateHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px var(--dropdown, 24px);
  border-radius: 6px;
  border: 1px solid rgb(var(--colors-gray-2));
  background: rgb(var(--colors-gray-1));
  gap: var(--dropdown, 24px);
  margin-bottom: 8px;
}

.useTemplateButton {
  background-color: rgb(var(--colors-gray-8));
  color: rgb(var(--colors-lantern-main-color));
}

.templateTitle {
  color: rgb(var(--colors-black));
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.templateSubtitle {
  color: rgb(var(--colors-gray-5));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.fileUploadedContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px var(--dropdown, 24px);
    border-radius: 6px;
    border: 1px solid rgb(var(--colors-navy-blue));
    background: rgba(5, 47, 255, 0.08);
    gap: var(--dropdown, 24px);
}

.fileUploader {
  padding: 30px var(--dropdown, 24px);
  border-radius: 6px;
  border: 1px dashed rgb(var(--colors-navy-blue));
  background: rgba(5, 47, 255, 0.08);
  gap: var(--dropdown, 24px);
  [data-file-uploader-top] {
    padding: 0 66px 0 0;
  }
  [data-file-uploader-top] {
    padding: 0 66px 0 0;

    [data-file-uploader-upload-block] {
      border: none;
      flex: 0 0 518px;
      flex-direction: column;
    }
    [data-file-uploader-upload-block] > button {
      background-color: rgb(var(--colors-gray-8));
      color: rgb(var(--colors-lantern-main-color));
    }
  }
}

.buttons {
  border-top: 1px solid rgb(var(--colors-gray-2));
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.cancelButton {
  border: 1px solid rgb(var(--colors-gray-5));
}

.doneButton {
  background-color: rgb(var(--colors-gray-8));
  color: rgb(var(--colors-lantern-main-color));
}

.uploadedFileName {
  color: rgb(var(--colors-navy-blue));
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.deleteFileButton {
  border: none;
  background-color: rgba(5, 47, 255, 0.08);
  color: rgb(var(--colors-gray-5));
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.fileUploadContent {
  overflow-y: auto;

  .tagsWrapper {
    margin: 8px 0 20px 0;
  }

  @include scrollBarStyles;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

