.wrapper {
  display: flex;
  flex-direction: column;
  width: 390px;
  background: rgba(var(--colors-white));
  margin: 0;
  padding: 0;
  border-top-right-radius: 8px;
}

.dateInputWrapper {
  display: flex;
  padding: 24px;
}