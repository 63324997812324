@import "../../../../../styles/config.scss";

.wrapper {
  display: flex;
  padding: 24px;
}

.commitment,
.available {
  padding: 8px;
}

.available {
  margin-left: 50px;
}

.label {
  font-size: 18px;
  font-weight: 500;
}

.value {
  font-size: 44px;

  span {
    font-size: 16px;
    margin-right: 5px;
  }
}
