@import '../../../../../styles/config';

.cell {
  margin: 0;
  padding: 0 2px 0 0;
  background-color: rgba(var(--colors-black), 0.75);
}

.cellInnerWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 10px;
  margin: 0;
  padding: 16px 12px 8px 12px;
  color: rgb(var(--colors-white));
  background-color: rgb(var(--colors-gray-8));
}

.submitBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-color: rgb(var(--colors-lan-green));
}

.submitBadgeIcon {
  svg {
    color: rgb(var(--colors-black));
  }
}

.fakeColumn {
  background-color: rgb(var(--colors-black));
}

.notVisible {
  display: none;
}

.editPenButton {
  margin-right: auto;
}
