.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.documentName {
  margin: 0 4px 0 12px;
  flex-grow: 1;
}

.fileIcon {
  color: rgb(var(--colors-white));
}

.lockIcon {
  color: rgb(var(--colors-gray-5));
}
