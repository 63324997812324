.wrapper {
  display: flex;
  align-items: center;
}

.legendWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.benchmarWrapper {
  display: flex;
  align-items: center;
}

.benchamrking {
  margin-left: 24px;
}

.legendItemsWrapper {
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
  user-select: none;

  &.readonly {
    cursor: default;
  }
}

.itemCircle {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
}

.legendItemsRow {
  display: flex;
  align-items: center;
  padding: 12px 0px;
}

.itemLabel {
  font-weight: 500;
  font-size: 14px;
  color: rgb(var(--colors-gray-4));
}

.disabled {
  pointer-events: none;
}

.actions {
  display: flex;
  gap: 24px;
}
