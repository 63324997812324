@import '../../../../styles/mixins';

.cell {
  font-size: 14px;
}

.tagsCell {
  font-size: 14px;
  width: 100%;
  height: 100%;
}

.actionButtons {
  display: flex;
  gap: 4px;
}

.actionButton {
  @include displayOnTableRowHover;

   &.isVisible {
    opacity: 1;
  }
}
