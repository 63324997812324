.wrapper {
  width: 100%;
}

.monitoringSectionHeader {
  position: relative;
  z-index: 3;
  margin-top: 40px;
  margin-bottom: 16px;
}

.layout {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  z-index: 1;

  &.collapsed {
    .sidebar {
      width: 56px;
      flex: 0 0 56px;
    }

    .main {
      width: calc(100% - 60px);
    }
  }
}

.sidebar {
  flex: 0 0 306px;
  width: 306px;
  transition: all 0.3s ease;
  z-index: 2;
}

.main {
  flex: 1 1 auto;
  width: calc(100% - 310px);
  transition: all 0.3s ease;
}

.dropdownWrapper {
  & > :nth-child(2) {
    left: 0;
    right: unset;
  }
  & > div > div > div > button:last-of-type {
    border-top: 1px solid rgb(var(--colors-gray-2));

    & > div {
      opacity: 1;

      svg {
        color: rgb(var(--colors-black));
      }
    }
  }
}
