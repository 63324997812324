@import "../../../styles/config.scss";

.wrapper {
  position: fixed;
  left: 0;
  bottom: 10%;
  margin-left: 10px;

  @include media(">=xlarge") {
    margin-left: 35px;
  }

  .uploadButton {
    width: 56px;
    height: 56px;
    background-color: rgb(var(--colors-gray-7));
  }
}
