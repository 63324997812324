.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;

  .delta {
    padding-left: 5.83px;
  }
}

.icon {

  svg {
    width: 10px;
    height: 10px;
  }
}
