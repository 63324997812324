.wrapper {
  display: flex;
  align-items: flex-start;
  &__primary {
    gap: 40px;
  }
  &__secondary {
    gap: 10px;
  }
}

.content {
  display: flex;
  align-items: center;
}

.fundColor {
  width: 4px;
  height: 24px;
}

.fundName {
  font-size: 20px;
  word-break: break-word;
  padding-left: 12px;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}
