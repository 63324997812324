@import "../../../../../styles/config";

.row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 0 12px 0;
  margin: 0;
}

.groupSubHeaderRowLeft {
  width: 30%;
}

.groupSubHeaderRowRight {
  width: 70%;
}

.groupSubHeaderCellSpan {
  font-size: 12px;
  font-weight: 500;
  color: rgb(var(--colors-gray-4));
}
