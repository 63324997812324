@import '../../styles/config.scss';

.wrapper {
  width: 100%;
  min-height: 100vh;
  max-width: 2078px;
  position: relative;
  z-index: 1;
  //overflow: auto;
}

.content {
  min-width: 1024px;
  min-height: 100vh;
  //overflow: auto;
  padding: 100px 24px 0 24px;

  @include media('>=xlarge') {
    padding: 100px 32px 0 128px;
  }
}
