@import "../../../../../styles/config";

.cell {
  margin: 0;
  padding: 11px 12px 4px 0;
  color: rgb(var(--colors-white));
  background-color: rgba(var(--colors-black), .75);
  font-size: 28px;
  font-weight: 400;
  text-align: left;
}

.notVisible {
  display: none;
}

