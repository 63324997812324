.wrapper {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  margin: 0 0 8px;
  color: rgb(var(--colors-gray-4));
  font-family: 'HalenoirText';

  &.secondary {
    width: 50%;
  }
}

.hint {
  font-size: 10px;
  line-height: 1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgb(var(--colors-black));
  margin-left: 4px;
  position: relative;
  top: -4px;
  cursor: pointer;
  text-align: center;
}
