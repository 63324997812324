@import '../../../styles/config';
@import '../../../styles/mixins';

.wrapper {
  position: relative;
  z-index: 2;

  &.active {
    z-index: 3;
  }
}

.inner {
  display: flex;
  align-items: baseline;
  font-size: 16px;
  line-height: 22px;
  color: rgb(var(--colors-lantern-main-color));
  white-space: nowrap;

  &__right {
    justify-content: flex-end;
  }

  &__left {
    justify-content: flex-start;
  }

  &__centered {
    justify-content: center;
  }
}

.subLabel {
  margin-right: 8px;
  font-size: 14px;
}

.value {
  font-size: 14px;
  line-height: 18px;
  color: rgb(var(--colors-lantern-main-color));
  font-weight: 500;
  display: block;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  border-radius: 38px;
  transition: background-color 0.2s ease;
  width: 100%;

  &:disabled {
    opacity: .8;
    color: rgb(var(--colors-gray-4));
  }

  .label {
    opacity: 1;
  }

  &__primary {
    padding: 10px 40px 10px 16px;
    border: 1px solid rgba(var(--colors-white), 0.3);

    &.active {
      background-color: rgb(var(--colors-white));
      color: rgb(var(--colors-black));

      .caret {
        .dropIcon {
          color: rgb(var(--colors-black));
        }
      }
    }
  }

  &__secondary {
    padding: 8px 40px 8px 8px;
    border: none;

    &.active {
      background-color: rgba(var(--colors-white), 0.22);
    }
  }

  @include media('>=large') {
    width: auto;
  }

  @include dropdown-controller-active;

  &.loading {
    pointer-events: none;
    position: relative;

    .loader {
      animation: rotation 1s infinite linear;
      position: absolute;
      height: 24px;
      width: 24px;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
    }

    .loaderIcon {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .label {
      opacity: 0;
    }

    .caret {
      opacity: 0;
    }
  }
}

.caret {
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  margin-top: -8px;
  right: 16px;
  transition: transform 0.2s ease;
}

.dropIcon {
  width: 100%;
  height: 100%;
}

.dropdown {
  position: absolute;
  top: 100%;
  margin-top: 8px;
  background: rgb(var(--colors-white));
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 1;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  &__right {
    right: 0;
    transform: translateY(-20px);
  }

  &__left {
    left: 0;
    transform: translateY(-20px);
  }

  &__centered {
    left: 50%;
    transform: translate(-50%, -20px);

    &.active {
      transform: translate(-50%);
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
