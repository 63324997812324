.wrapper {
  width: 800px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 2fr;
  gap: 18px 56px;

  .transactionsHeader {
    font-size: 12px;
    color: rgb(var(--colors-gray-4));
    font-weight: 500;
  }

  .rightPosition {
    text-align: right;
  }

  .transactionsRow {
    font-size: 14px;
  }
}
