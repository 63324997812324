@import '../../../../../styles/config';

.footerWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.submitButton {
  color: rgb(var(--colors-white));
  background-color: rgb(var(--colors-gray-8));

  &:hover {
    color: rgb(var(--colors-white));
    background-color: rgb(var(--colors-black));
  }

  &:focus {
    color: rgb(var(--colors-white));
    background-color: rgb(var(--colors-black));
  }

  &:disabled {
    color: rgb(var(--colors-gray-4));
    background-color: rgb(var(--colors-gray-6));
  }
}

.cancelButton {
  color: rgb(var(--colors-black));
  border-color: rgb(var(--colors-gray-5));

  &:hover {
    color: rgb(var(--colors-gray-7));
    border-color: rgb(var(--colors-gray-8));
  }

  &:focus {
    color: rgb(var(--colors-gray-7));
    border-color: rgb(var(--colors-gray-8));
  }

  &:disabled {
    color: rgb(var(--colors-gray-4));
    background-color: rgb(var(--colors-gray-4));
  }
}

.childrenWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 64px 44px 44px 44px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 100px;

  &__submit {
    background-color: rgb(var(--colors-lan-green));
  }

  &__update,
  &__cancel {
    background-color: rgb(var(--colors-orange-peel));
  }
}

.icon {
  svg {
    width: 32px;
    height: 32px;
    color: rgb(var(--colors-white));
  }
}

.text {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}
