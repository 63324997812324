@import '../../../../../styles/config';

.cell {
  margin: 0;
  padding: 0 2px 0 0;
  background-color: rgba(var(--colors-black), .75);
}

.cellInnerWrapper {
  height: 100%;
  margin: 0;
  padding: 0 12px 8px 12px;
  color: rgb(var(--colors-white));
  background-color: rgb(var(--colors-gray-8));
  font-size: 12px;
}

.submissionDateWrapper {
  color: rgb(var(--colors-gray-4));
  vertical-align: center;
}

.fakeColumn {
  background-color: rgb(var(--colors-black));
}

.notVisible {
  display: none;
}

.dateLabel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.icon {
  color: rgb(var(--colors-white));

  svg {
    width: 12px;
    height: 12px;
  }
}
