@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 0 48px 0;
}

.buttons {
  @include drawerFormButtons();
}

.deleteButton {
  margin-left: auto;
}

.errorMessage {
  @include drawerFormError();
}

.tooltipContainer {
  padding: 12px;
  width: 179px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
}

.labelIconContainer {
  margin-left: 5px;
}