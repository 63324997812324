.wrapper {
  position: absolute;
  z-index: 2;
  top: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    top: -4px;
    left: -4px;
    transition: all .3s ease;
    border-radius: 50%;
  }

  &:hover {
    z-index: 3;

    &:before {
      transform: scale(3);
      background-color: rgba(var(--colors-white), .25);
    }

    .point {
      transform: scale(1.6);
    }
  }

  &.expanded {
    &:hover {
      &:after {
        background-color: rgb(var(--colors-white));
      }
    }

    .point {
      background-color: rgb(var(--colors-gray-4));
    }
  }
}

.point {
  display: block;
  position: absolute;
  z-index: 2;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: rgb(var(--colors-gray-2));
  top: -4px;
  left: -4px;
  cursor: pointer;
  transition: all .3s ease;
}

.label {
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  text-align: center;
  visibility: hidden;
  z-index: 1;
  margin-left: -1px;
  color: rgb(var(--colors-gray-3));

  &.expanded {
    visibility: visible;
    transition: all .3s ease;

    &:hover {

      .labelText {
        background-color: rgb(var(--colors-white));
        color: rgb(var(--colors-gray-7));
      }

      .line {
        background-color: rgb(var(--colors-white));
      }

      .icon svg path {
        fill: rgb(var(--colors-black));
      }
    }

    .line {
      transition: all .3s ease;
    }

    .labelText {
      opacity: 1;
      transition: .4s opacity .3s ease;
    }

    &.five {
      top: -320px;
    }

    &.four {
      top: -260px;
    }

    &.three {
      top: -200px;
    }

    &.two {
      top: -140px;
    }

    &.one {
      top: -80px;
    }
  }
}

.labelText {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background-color: rgba(var(--colors-gray-8), 9);
  white-space: nowrap;
  border-radius: 48px;
  padding: 8px;
  position: relative;
  z-index: 2;
  opacity: 0;
  cursor: pointer;

  &.withIcon {
    padding-right: 16px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }
}

.icon {
  margin-right: 8px;
  color: rgb(var(--colors-white));

  svg {
    width: 24px;
    height: 24px;
  }
}

.line {
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 0;
  width: 1px;
  background-color: rgb(var(--colors-gray-4));
  transition: all .3s ease;

  &.five {
    height: 320px;
  }

  &.four {
    height: 260px;
  }

  &.three {
    height: 200px;
  }

  &.two {
    height: 140px;
  }

  &.one {
    height: 80px;
  }

  &.collapsed {
    height: 0;
    transition: none;
  }
}

.tooltip {
  display: flex;
  align-items: center;
  background: rgb(var(--colors-gray-7));
  border-radius: 8px;
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
  transform: translateY(-10px);
  text-align: center;
}
