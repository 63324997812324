@import '../../../styles';

.wrapper {
  display: flex;
  flex-direction: column;

  &__primary {
    .editor {
      color: rgb(var(--colors-gray-7));
    }
  }

  &__secondary {
    .editor {
      color: rgb(var(--colors-white));
    }
  }
}

.labelToolbarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.inputField {
  position: relative;
}

.editor {
  padding: 11px 50px 11px 24px;
  border-radius: 8px;
  border: 1px solid rgb(var(--colors-gray-5));
  font-size: 14px;
  width: 100%;
  background: none;
  min-height: 130px;
  outline: none;

  &:focus {
    border-color: rgb(var(--colors-lan-green));
  }

  &.inputInvalid {
    border-color: rgb(var(--colors-red));
  }
}

.placeholder {
  color: rgb(var(--colors-gray-4));
  position: absolute;
  pointer-events: none;
  // Refine this
  top: 3.75rem;
  left: 1.5rem;
}

.errorMessage {
  color: rgb(var(--colors-red));
  font-size: 12px;
}

.editorParagraph {
  margin-block-end: 0.1rem;
}

.editorListUl {
  margin-left: 20px;
}
.editorListUlItem {
  list-style-type: disc;
}

.editorTextBold {
  font-weight: bold;
}
.editorTextItalic {
  font-style: italic;
}

.richTextOutput {
  ul {
    margin-left: 20px;
    li {
      list-style-type: disc;
    }
  }
}

.inputField {
  position: relative;
}

.input {
  padding: 11px 50px 11px 24px;
  border-radius: 8px;
  border: 1px solid rgb(var(--colors-gray-5));
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  background: none;
  min-height: 130px;
  font-family: inherit;
  outline: none;

  &:focus {
    outline: none;
    border-color: rgb(var(--colors-lan-green));
  }
}

.inputLabel {
  display: flex;
  font-size: 14px;
  color: rgb(var(--colors-gray-5));
}

.errorMessage {
  position: absolute;
  bottom: calc(100% - 4px);
  right: 12px;
  background: rgb(var(--colors-red));
  font-size: 12px;
  padding: 8px;
  color: rgb(var(--colors-white));
  border-radius: 6px;
  display: none;

  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: rgb(var(--colors-red));
    position: absolute;
    bottom: -4px;
    right: 10px;
    transform: rotate(45deg);
  }
}

.inputInvalid {
  border: 1px solid rgb(var(--colors-red));

  ~ .icon__danger {
    color: rgb(var(--colors-red));
    display: block;
  }

  ~ .errorMessage {
    display: block;
  }
}

.inputValid {
  padding-right: 50px;

  ~ .icon__success {
    color: rgb(var(--colors-limeade));
    display: block;
  }
}

.input:disabled {
  color: rgb(var(--colors-gray-4));
  background: rgb(var(--colors-gray-7));
}

.input::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: rgb(var(--colors-gray-4));
}

.icon {
  position: absolute;
  top: 12px;
  right: 18px;
  display: none;
}
