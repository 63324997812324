.wrapper {
  padding: 34px 0;
  background-color: rgba(var(--colors-gray-8), 0.75);
  border-radius: 4px;
  position: relative;
  z-index: 1;

  &.tooltipOpen {
    z-index: 2;
  }
}

.top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 16px 16px 24px;
  gap: 6px;
}

.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  min-height: 72px;
  font-family: 'Halenoir';
}

.infoButton {
  align-self: flex-start;
}

.value {
  font-size: 44px;
  display: flex;
  align-items: baseline;
  padding: 0 24px;
}

.unit {
  font-size: 22px;
}

.deltaIndicator {
  //font-size: 14px;
}
