.wrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-bottom: 8px;

  &__secondary {
    align-items: flex-end;
  }
}

.title {
  font-size: 22px;
  margin-right: 16px;
  font-family: 'Halenoir';
}

.subtitle {
  font-size: 14px;
  margin-top: 5px;
}

.titleWrapper {
  display: flex;
  align-items: baseline;
}

.valueWrapper {
  font-size: 28px;
  padding-right: 24px;

  sub {
    font-size: 20px;
  }

  .currency {
    font-size: 14px;
    margin-right: 5px;
  }
}

.rightBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}

.primarySegmentedControl2 {
  min-width: 250px;
}
