@import '../../../styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid transparent;
  align-self: center;

  &:disabled {
    pointer-events: none;
  }

  &__primary {
    background-color: transparent;
    color: rgb(var(--colors-lantern-main-color));

    &:hover,
    &:focus,
    &.loading {
      background-color: rgb(var(--colors-gray-7));
      outline: none;
    }

    &:active,
    &.active {
      background-color: rgb(var(--colors-gray-6));
      outline: none;
    }

    &:disabled {
      color: rgb(var(--colors-gray-5));
    }
  }

  &__secondary {
    background-color: rgb(var(--colors-lantern-main-color));
    color: rgb(var(--colors-black));

    &:hover,
    &.loading {
      background-color: rgb(var(--colors-gray-3));
      outline: none;
    }

    &:active,
    &.active {
      background-color: rgb(var(--colors-gray-4));
      outline: none;
    }

    &:disabled {
      background-color: rgb(var(--colors-gray-4));
      color: rgb(var(--colors-gray-6));
    }
  }

  &__warning {
    background-color: rgb(var(--colors-red));
    color: rgb(var(--colors-lantern-main-color));

    &:hover,
    &.loading {
      background-color: rgb(var(--colors-sangria));
      outline: none;
    }

    &:active,
    &.active {
      background-color: rgb(var(--colors-lonestar));
      outline: none;
    }

    &:disabled {
      background-color: rgb(var(--colors-gray-4));
      color: rgb(var(--colors-gray-6));
    }
  }

  &__medium {
    .icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__small {
    padding: 5.5px;

    .icon {
      svg {
        width: 19px;
        height: 19px;
      }
    }
  }

  @include button-loading;
}
