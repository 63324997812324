@import '../../../styles/config';

.wrapper {
  padding: 24px 56px 24px 16px;
  margin-bottom: 40px;
  border-radius: 8px;
  color: rgb(var(--colors-white));
  background: rgba(var(--colors-black), .75);
  font-size: 16px;
  font-weight: 400;
}

.chatRow {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  font-size: 22px;
  font-weight: 500;
}

.chatRow:first-of-type {
  padding-bottom: 32px;
}

.chatRowIcon {
  svg {
    width: 24px;
    height: 24px;
  }
}

.chatBubble {
  padding: 16px 24px;
  color: rgb(var(--colors-white));
  background-color: rgb(var(--colors-gray-8));
  border-radius: 0 20px 20px 20px;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.chatBubble__isWaiting {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 164px;
}

.chatBubble__isNotFound {
  width: 100%;
  height: 228px;
  padding: 48px 60px;

  svg {
    width: 40px;
    height: 40px;
  }
}

.notFoundContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.notFoundTitle {
  padding-top: 32px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.notFoundSubTitle {
  font-family: 'HalenoirText', sans-serif;
  padding-top: 12px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.chatBubbleTitle {
  padding: 0 0 8px 40px;
  font-size: 14px;
  font-weight: 500;
}
