@import '../../../styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inner {
  padding: 64px 24px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.iconWrapper {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--colors-white));
  margin-bottom: 24px;
}

.icon {

  svg {
    width: 32px;
    height: 32px;
  }
}

.message {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 40px;
  text-align: center;
  font-family: 'Halenoir';
  word-break: break-word;
}

.buttons {
  border-top: 1px solid rgb(var(--colors-gray-2));
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
}

// Light theme buttons
@include buttons-light-theme;
