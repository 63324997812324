.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: var(--colors-black);
}

.logo {
  width: 68px;
  height: 10px;
}
