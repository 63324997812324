@import '../../../styles/config';
@import '../../../styles/mixins';

.dropdown {
  padding: 8px 4px 8px 0;
  width: max-content;
  text-align: right;
}

.dropdownInner {
  max-height: 244px;
  padding: 0 10px 0 0;
  overflow: auto;

  @include scrollBarStyles;

  &::-webkit-scrollbar-thumb {
    background-color: rgb(var(--colors-gray-4));
  }
}
