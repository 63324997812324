.wrapper {
  animation: rotation 1s infinite linear;
  position: absolute;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}