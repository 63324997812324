.toolbar {
  display: flex;
  padding: 0.5rem 0;
  gap: 0.5rem;
}

.active {
  background-color: pink;
  border-radius: 0.25rem;
}
