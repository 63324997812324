.notFoundContainer {
  background-color: rgb(var(--colors-black));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px 64px;
  border-radius: 8px;
}

.notFoundContainerIcon {
  margin-bottom: 32px;

  svg {
    width: 40px;
    height: 40px;
  }
}

.notFoundContainerTitle {
  font-size: 22px;
  margin-bottom: 12px;
}

.notFoundContainerSubTitle {
  font-size: 16px;
}
