@import "src/styles/config";

.wrapper {
  background-color: rgba(var(--colors-lantern-dark), 0.74);
  border-top: 4px solid transparent;
  padding: 26px 16px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media(">=small") {
    border-radius: 4px;
    padding: 28px 24px 24px;
  }
}

.bottom {
  border-top: 1px solid rgb(var(--colors-gray-7));
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 0 0 0;
}


