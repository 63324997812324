.wrapper {
  width: 390px;
  background-color: rgb(var(--colors-lantern-main-color));
}

.inner {
  display: grid;
  grid-template-columns: repeat(7, 32px);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  padding: 8px 0 8px 118px;
  height: 416px;
  overflow: auto;
  position: relative;
}

.monthDivider {
  position: relative;
  grid-column: 1 / span 7;
  margin: 20px 0;
  border-bottom: 1px solid rgb(var(--colors-gray-2));

  &:first-child {
    margin: 0;
    border-bottom: none;

    .monthName {
      top: 10px;
    }
  }
}

.monthName {
  font-size: 14px;
  width: 100px;
  position: absolute;
  text-align: center;
  top: 30px;
  left: -120px;
  color: rgb(var(--colors-gray-5));
}

.day {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: rgb(var(--colors-black));
  font-size: 12px;
  position: relative;
  z-index: 1;
  border: none;
  box-shadow: none;
  background: none;

  &:not(.empty) {
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      z-index: -1;
      border-radius: 15px;
      border: 1px solid transparent;
    }
  }

  &:hover:not(.empty) {

    &:before {
      background-color: rgb(var(--colors-gray-2));
    }
  }

  &.inRange {
    border-radius: 0;
    background: none;

    &:before {
      left: -2px;
      right: -2px;
      border-radius: 0;
      background-color: rgb(var(--colors-gray-2));
    }

    &:hover:before {
      background-color: rgba(var(--colors-gray-2), .8);
    }
  }

  &.start {
    background: none;
    color: rgb(var(--colors-lantern-main-color));

    &:before {
      left: 0;
      background-color: rgb(var(--colors-lan-green));
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &:hover:before {
      background-color: rgba(var(--colors-lan-green), .8);
    }
  }

  &.end {
    background: none;
    color: rgb(var(--colors-lantern-main-color));

    &:before {
      right: 0;
      background-color: rgb(var(--colors-lan-green));
      z-index: -1;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    &:hover:before {
      background-color: rgba(var(--colors-lan-green), .8);
    }
  }

  &.today {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 15px;
      border: 1px solid rgb(var(--colors-lan-green));
      z-index: -1;
    }
  }
}

.scrollContainer {
}
