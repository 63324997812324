.daysInWeek {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    gap: 4px;
    top: 0;
    z-index: 2;
    margin: 0;
    padding: 0 24px;
    width: 100%;
    height: 32px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: rgba(var(--colors-gray-5));
    background: rgba(var(--colors-gray-1));

    div {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.wrapper {
    width: 100%;
    margin: 0;
    background: rgba(var(--colors-white));
}