@import "../../../../../styles/config.scss";

.wrapper {
  display: flex;
  align-self: flex-start;
  gap: 12px;
}

.period {
  font-size: 24px;
  font-weight: 500;
  color: rgb(var(--colors-white));
}
