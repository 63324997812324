.wrapper {
  border-radius: 50%;

  &__primary {
    width: 8px;
    height: 8px;
  }

  &__secondary {
    width: 12px;
    height: 12px;
  }
}
