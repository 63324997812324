#filterWrapper {
  margin-bottom: 14px;
  position: relative;
  z-index: 2;
}

#additionalFilter {
  background-color: rgb(var(--colors-black));
  border-radius: 8px 8px 0 0;
  padding: 24px;
  position: relative;
  z-index: 1;
}

#additionalFilterLabel {
  margin-bottom: 8px;
}

#select {
  margin-bottom: 16px;
  z-index: 5;

  button {
    width: 100%;
    text-align: left;
  }

  & > div:nth-child(2) {
    width: 100%;

    & > div {
      width: 100%;
    }
  }
}

#chartWrapper {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding-top: 20px;
}

#chartLabel {
  padding: 12px 10px 12px;
  background-color: rgb(var(--colors-black));
  font-size: 12px;
  color: rgb(var(--colors-gray-5));
  border-radius: 0 0 6px 6px;
  text-align: center;
}
