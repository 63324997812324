@import '../../../../styles/recharts';

.wrapper {
  @extend %simpleRechartsTooltip;
  max-width: 295px;
  flex-direction: column;
  gap: 2px;
}

.treemapTooltipInfoSection, .treemapTooltipTitleSection {
  display: flex;
  align-items: center;
}

.treemapTooltipTitleSection {
  border-bottom: 1px solid rgb(var(--colors-gray-6));
  padding-bottom: 8px;
  margin-bottom: 8px;
}
