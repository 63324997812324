@import 'src/styles/mixins';

.wrapper {
  //transition: background-color .3s ease;

  &.loading {
    background: rgb(var(--colors-gray-8));
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .spinner {
    color: rgb(var(--colors-gray-6));
    svg {
      width: 48px;
      height: 48px;
    }
  }
}

.errorMessage {
  @include drawerFormError();
}

.buttons {
  @include drawerFormButtons();
}
