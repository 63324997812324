.wrapper {
  padding: 0 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  cursor: text;
  background-color: rgb(var(--colors-lantern-main-color));
  border-color: transparent;
  border-radius: 24px;

  &.moreThenOneRow {
    border-radius: 20px;
  }
}

.icon {
  color: rgb(var(--colors-black));
  margin-top: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.textarea {
  min-height: 48px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: rgb(var(--colors-black));
  border: none;
  background: none;
  resize: none;
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;
  font-family: 'HalenoirText';

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgb(var(--colors-gray-4));
  }
}
