.wrapper {
  padding: 12px;
  border-radius: 8px;
  background-color: rgb(var(--colors-gray-7));
  white-space: nowrap;
  font-size: 14px;
}

.title {
  padding-bottom: 8px;
  border-bottom: 1px solid rgb(var(--colors-gray-6));
}

.list {

  li {
    display: flex;
    align-items: center;
    padding-top: 8px;

    b {
      font-weight: 600;
      margin-right: 5px;
    }
  }
}

.dot {
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
