@import '../../../../../styles/config';

.cell {
  margin: 0;
  padding: 0 2px 0 0;
  background-color: rgba(var(--colors-black), .75);
}

.cellInnerWrapper {
  height: 100%;
  margin: 0;
  padding: 0 12px 0 0;
  color: rgb(var(--colors-white));
  font-weight: 400;
  text-align: left;
  font-size: 14px;
}

.notVisible {
  display: none;
}
