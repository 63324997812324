@import '../../../styles/config.scss';

.wrapper {
  width: 100%;
  margin-top: 80px;
}

.widgets {
  display: flex;
  flex-direction: column;
  margin: 0 -8px -16px;
  align-items: stretch;
  justify-content: space-between;

  @include media('>=large') {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include media('>=xlarge') {
    flex-wrap: nowrap;
    margin: 0 -8px;
  }
}

.widgetsItem {
  width: 100%;
  padding: 0 8px 16px;

  @include media('>=large') {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @include media('>=xlarge') {
    flex: 1 1 33%;
    // [LP-3574] TODO: Uncomment max-width: 33%; below when its time to make the Investment Card visible again - Aleksa Jukic
    //max-width: 33%;
    padding: 0 8px;
  }
}

.sectionHeader {
  margin-bottom: 16px;
}
