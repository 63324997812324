@import '../../../styles/config';
@import '../../../styles/mixins';

.dropdown {
  padding: 8px 4px 8px 0;
  width: max-content;
  text-align: right;
}

.dropdownInner {
  max-height: 244px;
  padding: 0 10px 0 0;
  overflow: auto;

  @include scrollBarStyles;

  &::-webkit-scrollbar-thumb {
    background-color: rgb(var(--colors-gray-4));
  }
}

.fullContent {
  max-height: max-content;
}

.group {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  border-bottom: 1px solid rgb(var(--colors-gray-2));

  &:last-child {
    border-bottom: none;
  }
}

.groupLabel {
  display: flex;
  align-self: stretch;
  width: 30%;
  min-width: 190px;
  padding: 8px 24px;
  color: rgb(var(--colors-gray-5));
  font-size: 14px;
}

.groupItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}
