@import '../../../../styles/config.scss';

.cell {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.starTh {
  flex-direction: row-reverse;
  justify-content: center;
  padding-left: 12px;
}

.star {
  cursor: pointer;
  color: rgb(var(--colors-lantern-main-color));
}
