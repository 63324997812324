@import '../../../../../styles/config';

.cell {
  margin: 0;
  padding: 0 2px 0 0;
  background-color: rgba(var(--colors-black), .75);
}

.cellInnerWrapper {
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;
  color: rgb(var(--colors-white));
  background-color: rgb(var(--colors-gray-8));
  font-size: 12px;
}

.submissionDateWrapper {
  color: rgb(var(--colors-gray-4));
  vertical-align: center;
}

.submissionDateOverdue {
  color: rgb(var(--colors-white));
}

.submissionDate {
  white-space: nowrap;
}

.activityStatusOverride {
  display: inline-block;
  margin-right: 4px;
}

.fakeColumn {
  background-color: rgb(var(--colors-black));
}

.notVisible {
  display: none;
}
