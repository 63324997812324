.wrapper {
  width: 100%;
}

.kpisSectionHeader {
  margin-bottom: 16px;
}

.tabs {
  flex-wrap: nowrap;
  gap: 0;
  white-space: nowrap;
}

.tabsSectionsWrapper {
  display: flex;
  overflow: auto;
}

.tabsSection {
  &:not(:first-child) {
    margin-left: 24px;
    padding-left: 24px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 12px;
      width: 1px;
      background-color: rgb(var(--colors-gray-4));
    }
  }
}

.tabsSectionLabel {
  font-size: 14px;
  color: rgb(var(--colors-gray-4));
  margin-bottom: 12px;
  padding-left: 14px;
}
