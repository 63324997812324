.wrapper {
  width: 100%;
}

.comment {
  font-size: 14px;
  line-height: 18px;
  max-width: 100%;
  white-space: pre-wrap;

  &__collapsed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.expandButton {
  border: none;
  background: none;
  color: rgb(var(--colors-gray-5));
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  cursor: pointer;
}