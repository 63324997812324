.placeholder {
  background-color: rgba(var(--colors-black), 0.75);
  padding: 40px 80px 40px 40px;
  position: relative;
  font-size: 18px;
  color: rgb(var(--colors-gray-4));
  display: flex;
  align-items: center;
  min-height: 112px;
  margin-bottom: 80px;
}

.infoHeader {
  margin-bottom: 80px;

  a {
    font-size: 20px;
  }
}

.editButton {
  position: absolute;
  top: 36px;
  right: 40px;
}

.sectionHeader {
  margin: 4px 0 16px 0;
}
