@import '../../../styles/config';

.wrapper {
  border: 1px solid rgba(var(--colors-white), 0.3);
  border-radius: 30px;
  padding: 4px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  height: 40px;

  &__primary {
    background-color: transparent;

    .icon {
      color: rgb(var(--colors-white));

      &__active {
        color: rgb(var(--colors-black));
      }
    }
  }

  &__secondary {
    background-color: rgb(var(--colors-gray-7));
    border: none;

    .icon {
      color: rgb(var(--colors-gray-4));

      &__active {
        color: rgb(var(--colors-white));
      }
    }
  }

  &__tertiary {
    background-color: transparent;

    .icon {
      color: rgb(var(--colors-gray-4));

      &__active {
        color: rgb(var(--colors-white));
      }
    }
  }

  &__small {
    padding: 2px;
    height: 32px;
  }
}

.option {
  display: block;
  flex: 1 1 auto;
  text-align: center;
  min-width: 28px;

  input[type='radio'] {
    display: none;
  }
}

.optionInner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 5px 12px;
  border-radius: 30px;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
  gap: 5px;

  &__primary {
    color: rgb(var(--colors-black));
    font-weight: 600;
  }

  &__secondary {
    color: rgb(var(--colors-white));
    font-weight: 600;
  }

  &__tertiary {
    color: rgb(var(--colors-black));
    font-weight: 600;
  }

  &__small {
    padding: 2px;
    border-radius: 20px;
  }
}

.activeBlock {
  position: absolute;
  height: calc(100% - 8px);
  top: 4px;
  transition: all 0.3s ease;
  border-radius: 30px;

  &__primary {
    background-color: rgb(var(--colors-white));
  }

  &__secondary {
    background-color: rgb(var(--colors-black));
  }

  &__tertiary {
    background-color: rgb(var(--colors-gray-4));
  }

  &__small {
    height: calc(100% - 4px);
    top: 2px;
    border-radius: 40px;
  }
}

.withCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.icon {

  &__small {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__medium {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
