.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px 60px;
}

.button {
  font-weight: 400;
  line-height: 27px;
  padding: 0 8px 14px 8px;
  margin: 0;
  border: none;
  background: none;
  box-shadow: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  color: rgb(var(--colors-lantern-main-color));

  &__primary {
    font-size: 20px;
    font-family: 'Halenoir';
  }

  &__secondary {
    font-size: 16px;
    padding: 0 14px 16px 14px;
  }

  &.active {
    border-color: rgb(var(--colors-lan-green));
    font-weight: 600;
  }

  &.disabled, &.navigationBlocked {
    position: relative;
    color: rgb(var(--colors-gray-4));
    cursor: auto;
    pointer-events: none;
  }

  &.disabled {
    &:before {
      content: 'Coming soon';
      color: rgb(var(--colors-green-haze));
      position: absolute;
      top: -24px;
      left: 10px;
      font-size: 12px;
      white-space: nowrap;
    }
  }
}

// Small variant
.wrapper.small .button {
  font-size: 16px;
}
