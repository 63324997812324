@import '../../../styles/mixins';

.option {
  @include selectOption;

  &:focus {
    background-color: rgb(var(--colors-gray-1));
  }

  &.disabled {
    color: rgb(var(--colors-gray-3));
    pointer-events: none;
  }
}
