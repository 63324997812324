@import "../../../styles/config";

.wrapper {
  border-radius: 8px;
  background-color: rgba(var(--colors-black));
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 40px;
  color: rgb(var(--colors-white));
  background-color: rgb(var(--colors-gray-8));
  border-radius: 8px 8px 0 0;
}

.paginationYearText {
  font-size: 24px;
  font-weight: 500;
}

.paginationSection {
  display: flex;
  align-items: center;
}

.paginationSectionText {
  color: rgb(var(--colors-gray-4));
  font-size: 12px;
  font-weight: 400;
  padding-right: 12px;
}

.questionnaire {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 0 0 8px 8px;
}

.mandatoryMark {
  color: rgb(var(--colors-lan-green));
}
