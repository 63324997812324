.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  background-color: rgb(var(--colors-white));
  border-radius: 0 0 8px 8px;
  transition: all 0.3s ease;
  user-select: none;
  min-height: 50px;
  max-height: 80vh;
  overflow-y: auto;

  a {
    text-decoration: none;
    color: unset;
  }

  &.loading {
    height: 100px;
  }
}

.resultsLoading {
  height: 150px;
}

.group {
  border-top: 1px solid rgb(var(--colors-gray-2));
  padding: 16px 0;
  color: rgb(var(--colors-gray-5));
  display: flex;
  align-items: baseline;
}

.groupName {
  font-size: 14px;
  line-height: 18px;
  padding: 8px 24px;
  flex: 0 0 136px;
  position: relative;
  z-index: 1;
}

.resultsFooter {
  display: flex;
  width: 100%;
  padding-right: 24px;

  .list {
    margin-right: auto;
    flex: auto;
  }
}
.list {
  flex: 1 1 100%;
}

.listItem {
  a {
    display: block;
    padding: 8px 20px 8px 136px;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 0 -136px;
    border: 2px solid transparent;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:hover {
    a {
      background-color: rgb(var(--colors-gray-2));
      border-left: 2px solid rgb(var(--colors-lan-green));
    }
  }
}

.highlight {
  color: rgb(var(--colors-black));
}

.hint {
  font-size: 14px;
  line-height: 18px;
}

.spinner {
  border-color: rgba(var(--colors-lantern-dark), 0.2);

  &:before {
    border-top-color: rgba(var(--colors-lantern-dark), 0.8);
  }
}

.skeletonLoader {
  border-right: 24px solid rgb(var(--colors-white));
}
