@import '../../../styles/mixins';

.dropdown {
  width: 375px;
}

.group {
  padding: 24px 20px 12px 145px;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:not(:first-child):before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 24px;
    left: 146px;
    border-top: 1px solid rgb(var(--colors-gray-2));
  }
}

.groupButton {
  background-color: rgb(var(--colors-gray-2));
  border-radius: 48px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px;
  margin: 0 4px 12px 4px;
  color: rgb(var(--colors-gray-7));
  cursor: pointer;

  &:hover {
    background-color: rgb(var(--colors-gray-4));
  }

  &.active {
    background-color: rgb(var(--colors-gray-6));
    color: rgb(var(--colors-lantern-main-color));
  }
}

.groupTitle {
  font-size: 14px;
  color: rgb(var(--colors-gray-5));
  position: absolute;
  top: 24px;
  left: 24px;
}
.sourceTitle {
  font-size: 14px;
  color: rgb(var(--colors-gray-5));
  position: absolute;

  left: -60px;
}
.sourceWrapper {
  position: relative;
  margin-bottom: 24px;
}

.actions {
  padding: 0px 24px 40px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.actionButton {
  margin-left: 16px;
  font-size: 14px;
}
