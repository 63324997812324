.wrapper {
  background-color: rgb(var(--colors-black));
  padding: 32px 24px;
}

.top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgb(var(--colors-gray-7));
  margin-bottom: 32px;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.buttons {
  display: flex;
  gap: 10px;
}

.count {
  color: rgb(var(--colors-gray-4));
  margin-bottom: 16px;
  font-size: 14px;
  text-transform: lowercase;
}

.options {
  margin: 0 -16px 0 0;
  max-height: 640px;
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgb(var(--colors-gray-2));
  }
}

.optionsInner {
  padding: 0 8px 0 0;
}
