@import '../../../styles/mixins.scss';

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: 'Halenoir';
  overflow: unset;

  &__locked {
    position: absolute;
    overflow: hidden;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 24px 24px;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.icon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--colors-white));
  margin-bottom: 24px;
  background-color: rgb(var(--colors-orange-peel));

  svg {
    width: 32px;
    height: 32px;
  }
}

.message {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

// Light theme buttons
@include buttons-light-theme;
