@import "../../../../../styles/config";

.cell {
  margin: 0;
  padding: 0 2px 0 0;
  background-color: rgba(var(--colors-black), .75);
}

.cellInnerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0;
  padding: 12px 16px;
  color: rgb(var(--colors-white));
  background-color: rgb(var(--colors-gray-8));
}

.headerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 21px 0;
}

.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.forecastDuration {
  color: rgb(var(--colors-white));
  font-size: 16px;
  font-weight: 500;
  padding: 0;
}

.forecastDueDate {
  color: rgb(var(--colors-gray-4));
  font-size: 12px;
  font-weight: 400;
  padding: 0;
}

.forecastPagination {
  color: rgb(var(--colors-gray-4));
  font-size: 12px;
  font-weight: 400;
}

.submitBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-color: rgb(var(--colors-lan-green));
}

.submitBadgeIcon {
  svg {
    color: rgb(var(--colors-black));
  }
}

.notVisible {
  display: none;
}
