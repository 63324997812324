@import "src/styles/config";

.wrapper {
  width: 100%;
  height: 100%;
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin: 10px 0 25px;
}

.charts {
  display: flex;
  gap: 16px;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;

  @include media(">=xlarge") {
    flex-wrap: nowrap;
  }
}

.chartsItems {
  margin-bottom: 14px;
  width: 100%;
  flex-direction: row;
  width: 50%;
  flex: 49% 0 0;

  @include media(">=xlarge") {
    width: 33%;
    flex: 33% 1 1;
  }
}
