%companiesSection {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;

  @include media(">=large") {
    grid-template-columns: repeat(2, calc(50% - 10px));
  }

  @include media(">=xlarge") {
    grid-template-columns: repeat(3, calc(33% - 7px));
  }

  @include media(">=xxlarge") {
    grid-template-columns: repeat(4, calc(25% - 12px));
  }
}
