.formWrapper {
  .formInputRowWrapper {
    padding: 32px 40px;
    border-radius: 8px;
    background: rgba(var(--colors-lantern-dark), 0.75);

    .formInputRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .formInputWrapper {
        width: 288px;
        height: 66px;

        .formInput {
          color: rgb(var(--colors-white));

          .dateInput {
            & > :nth-child(1) {
              button {
                display: flex;
                width: 100%;
                height: 40px;
                padding: 11px 50px 11px 24px;
                border-radius: 30px;
              }
            }
          }

          .formInputLabel {
            font-size: 14px;
            margin-bottom: 8px;
            color: rgb(var(--colors-gray-5));
          }

          & > div > label {
            color: rgb(var(--colors-gray-3));
          }
        }
      }
    }
    .description {
      margin-top: 32px;

      .descriptionInput {
        height: 115px;
        color: rgb(var(--colors-white));
      }
    }
  }
  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    .main {
      margin-left: 16px;
    }
  }
}

