@import "../../../styles/config";
@import "../../../styles/mixins";

.dropdown {
  width: 390px;
}

.group {
  padding: 24px 20px 12px 122px;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:not(:first-child):before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 24px;
    left: 126px;
    border-top: 1px solid rgb(var(--colors-gray-2));
  }
}

.groupTitle {
  font-size: 14px;
  line-height: 18px;
  color: rgb(var(--colors-gray-5));
  position: absolute;
  top: 24px;
  left: 24px;
  width: 80px;
}

.groupButton {
  background-color: rgb(var(--colors-gray-2));
  color: rgb(var(--colors-black));
  border-radius: 48px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px;
  margin: 0 4px 12px 4px;
  cursor: pointer;

  &:hover {
    background-color: rgb(var(--colors-gray-4));
  }

  &.active {
    background-color: rgb(var(--colors-gray-6));
    color: rgb(var(--colors-lantern-main-color));
  }

  &.disabled {
    background-color: rgb(var(--colors-gray-1));
    color: rgb(var(--colors-gray-3));
    pointer-events: none;
  }
}

.actions {
  padding: 20px 24px 40px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

// Light theme buttons
@include buttons-light-theme;
