.investmentsSectionHeader {
  margin-top: 80px;
  margin-bottom: 16px;
}

.subsection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  .buttonsWrapper {
    display: flex;
    gap: 24px;
  }
}
