.wrapper {
  background-color: rgb(var(--colors-gray-7));
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 6px;
  border-radius: 4px;
  position: fixed;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  box-shadow: 0px 2px 8px 0px rgba(var(--colors-black), 0.3);

  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: rgb(var(--colors-gray-7));
    position: absolute;
    transform: rotate(45deg);
  }

  &.hideArrow::before {
    display: none;
  }

  &.isShown {
    opacity: 1;
    visibility: visible;
  }

  &.bottom {
    &:before {
      top: -4px;
      left: 50%;
      margin-left: -4px;
    }
  }

  &.top {
    &:before {
      bottom: -4px;
      left: 50%;
      margin-left: -4px;
    }
  }

  &.left {
    &:before {
      right: -4px;
      top: 50%;
      margin-top: -4px;
    }
  }

  &.right {
    &:before {
      left: -4px;
      top: 50%;
      margin-top: -4px;
    }
  }
}
