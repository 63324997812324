@import '../../styles/config.scss';

.navbar {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
  z-index: 2;
  transition: all 0.3s ease;

  @include media('>=large') {
    height: 80px;
  }

  &.sticky {
    position: fixed;
    background-color: rgb(var(--colors-black));

    .logo {
      @include media('>=xlarge') {
        display: flex;
      }
    }
  }
}

.logo {
  display: none;
  margin: 0 auto 0 20px;
}

.navLeft {
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
}

.topNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @include media('>=large') {
    margin-right: 24px;
  }

  @include media('>=xlarge') {
    margin-right: 32px;
  }
}

.navItemNotificaiton {
  margin-left: 20px;
  margin-right: 20px;

  @include media('>=large') {
    margin-right: 0;
  }

  &.disabled {
    svg path {
      fill: rgb(var(--colors-gray-5));
    }
  }
}

.navItemAccount {
  margin-left: 24px;
  display: none;

  @include media('>=large') {
    display: flex;
    flex-direction: row;
  }
}

.navListSearch {
  order: 2;

  @include media('>=large') {
    order: unset;
  }
}

.navItemToggle {
  margin: 0 24px 0 0;
  order: 1;

  @include media('>=large') {
    order: unset;
    margin: 0 0 0 32px;
  }
}

.navItemUserProfile {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 5px;
}

.navSearch {
  width: 48px;
  height: 48px;

  @include media('>=large') {
    width: 35vw;
  }
}
