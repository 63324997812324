.title {
  margin-bottom: 16px;
}

.wrapper {
  width: 800px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 18px 56px;
  font-size: 12px;

  .reportingPeriodsHeader {
    color: rgb(var(--colors-gray-4));
    font-weight: 500;
  }

  .filesCol {
    text-align: right;
    width: 30px;
  }
}
