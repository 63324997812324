.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.error {
  margin: 0 0 8px 0;
}

.panel {
  padding: 0;

  [class*='options'] {
    overflow: visible;
    max-height: none;
    margin: 0;
  }
}
