@import '../../../../../styles/config.scss';

.wrapper {
  display: flex;
  align-items: flex-start;
}

.chart {
  width: calc(100% - 150px);
  height: 150px;

  @include media('>=xxlarge') {
    width: calc(100% - 200px);
  }
}

.legend {
  width: 150px;
  flex: 0 0 150px;

  @include media('>=xxlarge') {
    width: 200px;
    flex: 0 0 200px;
  }
}

.legendItem {
  display: flex;
  align-items: center;
  padding: 0 0 16px;

  &.active {
    font-weight: 600;
  }

  &.inActive {
    opacity: .5;
  }
}

.legendMarker {
  flex: 0 0 8px;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;
}

.icon {
  margin-left: 16px;
}
