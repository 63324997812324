.dropdownWrapper {
  position: relative;
  z-index: 1;

  &.isOpen {
    z-index: 2;
  }
}

.inputWrapper {
  width: 100%;
}

.options {
  background-color: rgb(var(--colors-lantern-main-color));
  border-radius: 8px;
  width: 100%;
  padding: 8px 0;
}

.optionsItem {
  display: block;
  padding: 8px 40px;
  color: rgb(var(--colors-black));
  cursor: pointer;
  font-weight: 400;

  &.selected {
    background-color: rgb(var(--colors-gray-2));
  }

  &:hover {
    background-color: rgb(var(--colors-gray-1));
  }
}
