@import '../../../styles/config';

.wrapper {
  padding: 30px 20px;
  font-family: 'Halenoir';
  position: relative;

  &__primary {
    background-color: rgba(var(--colors-black), 0.75);
  }

  &__secondary {
    background-color: transparent;
  }

  &__editable {
    padding-right: 80px;
  }
}

.gridInner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

  &:empty {
    display: none;
  }
}

.flexInner {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 8px;

  &:empty {
    display: none;
  }
}

.flexColumn {
  padding: 10px 20px;
  margin-bottom: 20px;
}

.column {
  padding: 10px 20px;
  margin-bottom: 20px;
  line-height: 1.3;
  word-break: break-word;

  &__nobreak {
    word-break: unset;
  }
}

.editButton {
  position: absolute;
  top: 40px;
  right: 40px;
}
