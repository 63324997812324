.headerWrapper {
  display: flex;
  justify-content: space-between;

  .transactionsWrapper {
    display: flex;
    align-items: baseline;
    font-size: 22px;
    gap: 16px;

    .transactionsCount {
      font-size: 20px;
      line-height: 26px;
      padding: 4px 8px;
      background-color: rgb(var(--colors-gray-7));
      border-radius: 6px;
      text-align: center;
    }
  }

  .buttonsWrapper {
    display: flex;
    gap: 16px;
  }
}
