.wrapper {
  line-height: 22px;
  min-width: 70px;
  max-width: fit-content;
  padding: 2px 12px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
