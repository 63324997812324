.tooltip {
  padding: 12px;
  border-radius: 8px;
  background: rgb(var(--colors-gray-7));
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: rgb(var(--colors-white));
}

.line {
  width: 100%;
  height: 1px;
  background: rgb(var(--colors-gray-6));
}

.status {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  margin-left: 6px;

  &:hover {
    color: rgb(var(--colors-white));
    cursor: pointer;
  }
}
