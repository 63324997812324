@import '../../../styles/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.tagWrapper {
  border-radius: 20px;
  border: 1px solid rgb(var(--colors-gray-6));
  position: relative;
  z-index: 1;

  &:not(.focused) {
    .input {
      min-width: 1px;
      width: 1px;
    }
  }

  &.focused {
    border-color: rgb(var(--colors-lan-green));
  }
}

.inner {
  padding: 3px 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  cursor: text;
  min-height: 38px;

  &__withIcon {
    padding-left: 16px;
  }
}

.icon {
  margin-right: 12px;
}

.input {
  @include transparentInput;
}

.dropdownItem {
  display: flex;
  padding: 8px 40px;
  color: rgb(var(--colors-black));
  cursor: pointer;
  font-weight: 400;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  text-align: left;

  &:hover {
    background-color: rgb(var(--colors-gray-1));
  }

  &:focus {
    background-color: rgb(var(--colors-gray-2));
  }
}

.notVisible {
  display: none;
}

.inputLabel {
  font-size: 14px;
  margin-bottom: 8px;
  color: rgb(var(--colors-gray-5));
}

.group {
  &:not(:last-child) {

    &:after {
      content: '';
      display: block;
      margin: 12px 16px;
      border-bottom: 1px solid rgb(var(--colors-gray-2));
    }
  }
}
