.cell {
  margin: 0;
  padding: 0 2px 0 0;
  background-color: rgba(var(--colors-black), 0.75);

  .cellInnerWrapper {
    height: 100%;
    margin: 0;
    color: rgb(var(--colors-white));
    background-color: rgb(var(--colors-gray-8));
    border-top: 1px solid rgb(var(--colors-gray-5));
  }

  .submissionDocumentswrapper {
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
  }

  .fakeColumn {
    background-color: rgb(var(--colors-black));
    border: none;
  }

  .documents {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.notVisible {
  display: none;
}
