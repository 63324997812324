@import '../../../../../styles/config';

.cell {
  margin: 0;
  padding: 19px 12px 19px 0;
  color: rgb(var(--colors-white));
  background-color: rgba(var(--colors-black), 0.75);
  border-top: 1px solid rgb(var(--colors-gray-7));
  font-size: 12px;
  font-weight: 400;

  &:nth-child(2) {
    font-size: 14px;
  }
}

.tooltip {
  max-width: 180px;
  padding: 12px;
  border-radius: 8px;
}

.notVisible {
  display: none;
}

.clickable {
  cursor: pointer;
}
