@import "../../../styles/config";

.wrapper {
  display: flex;
  align-items: center;
  margin: 0 auto 0 24px;

  @include media(">=large") {
    margin-right: 64px;
  }

  &.hidden {
    display: none;
  }
}

.logo {
  display: none;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  font-size: 12px;
  margin-right: 16px;

  @include media(">=large") {
    display: flex;
  }
}

.title {
  display: none;
  font-weight: 500;
  font-size: 18px;
  margin-right: 16px;
  line-height: 24px;

  @include media(">=medium") {
    display: block;
  }

  @include media(">=large") {
    font-size: 20px;
    line-height: 26px;
  }
}

.subTitle {
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
}

.colorLine {
  display: none;

  @include media(">=medium") {
    display: block;
    width: 2px;
    height: 20px;
    margin-right: 12px;
  }
}
