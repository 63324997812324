.wrapper {

  &__primary {
    .label {
      color: rgba(var(--colors-black));
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 18px;
      font-family: 'HalenoirText', sans-serif;
    }

    .colors {
      width: 252px;
    }

    .color {
      outline-color: rgba(var(--colors-gray-3));
    }
  }

  &__secondary {
    .label {
      color: rgba(var(--colors-gray-4));
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    .colors {
      padding: 16px;
      border: 1px solid rgba(var(--colors-gray-4));
      border-radius: 8px;
      width: 248px;
    }

    .color {
      outline-color: rgba(var(--colors-white));
    }
  }
}



.colors {
  display: flex;
  gap: 12px 14px;
  flex-wrap: wrap;
}

.input {
  display: none;

  &:checked + .inputLabel {
    .color {
      outline-width: 4px;
      outline-style: solid;
    }

    .inputLabelOverlay {
      opacity: 1;
    }
  }
}

.inputLabel {
  position: relative;
  cursor: pointer;

  &:hover {
    .color {
      outline-width: 4px;
      outline-style: solid;
    }
  }
}

.color {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all .1s ease-in-out;
}

.inputLabelOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: .1s ease-in-out;
}

.checkIcon {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);
  color: rgba(var(--colors-white));
}
