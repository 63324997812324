.tablePlaceholder {
  width: 660px;
  max-width: 100%;
  margin: 0 auto;
  border-top: 1px solid rgb(var(--colors-gray-7));
  padding: 32px 0 65px 0;
  font-size: 16px;
}

.tablePlaceholderSection {
  margin: 0 0 24px;
}

.tablePlaceholderTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 12px;
}
