.wrapper {
  background-color: rgba(var(--colors-black), 0.75);
  border-radius: 8px;
  padding: 32px 40px 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  line-height: 26px;
  margin: 0 0 34px 0;
  font-family: 'Halenoir';
}

.editButton {
  position: absolute;
  top: 20px;
  right: 20px;
}

.teamListLabel {
  font-size: 12px;
  color: rgb(var(--colors-gray-4));
  text-align: right;
  margin-bottom: 12px;
}

.teamList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.teamItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.teamIndicator {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(var(--colors-gray-6));

  &.active {
    background-color: rgb(var(--colors-lan-green));
  }
}

.paginationWrapper {
  margin-top: auto;
  padding: 16px 0;
}
