@import '../../../../styles/mixins';

.mandatory {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.edit {
  @include displayOnTableRowHover;
}

.cell {
  font-size: 14px;
}
