@import '../../../../../../styles/config';
@import '../../../../../../styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .editButton {
    @include displayOnTableRowHover;
    padding: 4px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.tagsContainer {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
}

.tag {
  tr:hover & {
    background-color: rgb(var(--colors-gray-8)) !important;
  }
}

.tagMarginRight {
  margin-right: 4px;
}
