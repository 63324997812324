@import '../../../styles/mixins.scss';

.dropdown {
  width: max-content;
  max-width: 400px;
  max-height: 500px;
  overflow: auto;

  li {
    font-size: 16px;
    line-height: 22px;
    color: rgb(var(--colors-black));
    padding: 16px 24px;
    white-space: normal;
    border-bottom: 1px solid rgb(var(--colors-gray-2));

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-bottom: none;
      border-radius: 0 0 8px 8px;
    }

    a,
    button {
      color: rgb(var(--colors-black));

      &:hover {
        color: rgb(var(--colors-lan-green));
      }
    }

    button {
      font-size: 16px;
      border: none;
      background: none;
      cursor: pointer;
      text-align: left;
    }
  }

  @include scrollBarStyles;

  &::-webkit-scrollbar-thumb {
    background-color: rgb(var(--colors-gray-4));
  }
}
