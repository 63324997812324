.formWrapper {
  width: 100%;
  background: var(--nearcasting-gradient);

  .title {
    font-size: 22px;
    color: rgb(var(--colors-white));
    margin: 24px 0 0 24px;
  }

  .formInputRowWrapper {
    padding: 24px 24px 0;
    border-radius: 8px;

    .formInputRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .formInputWrapper {
        width: 276px;
        height: 66px;
        margin-bottom: 24px;

        .formInput {
          color: rgb(var(--colors-white));

          .formInputLabel {
            font-size: 14px;
            margin-bottom: 8px;
            color: rgb(var(--colors-gray-5));
          }

          &>div>label {
            color: rgb(var(--colors-gray-3));
          }
        }

        .selectDropdown {
          span {
            color: rgb(var(--colors-white));;
          }
        }
      }
    }

    .description {
      margin-top: 32px;

      .descriptionInput {
        height: 115px;
        color: rgb(var(--colors-white));;
      }
    }
  }

  .buttonsWrapper {
    margin: 32px 24px;
  }
}