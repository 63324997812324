.wrapper {
  padding: 55px 0;
  background: rgb(var(--colors-gray-7));
  color: rgb(var(--colors-gray-4));
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.icon {
  svg {
    width: 32px;
    height: 32px;
  }
}

.label {
  font-weight: 500;
}
