@import "../../../styles/config.scss";

.wrapper {
  height: 48px;
  position: relative;
  z-index: 1;

  &.isFocused {
    position: absolute;
    top: 6px;
    left: 20px;
    right: 20px;
    width: auto;

    @include media(">=large") {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      width: 35vw;
    }
  }
}
