.legend {
  display: table;

  &.withHead {
    margin-top: -16px;
  }
}

.legendItem {
  align-items: flex-start;
  justify-content: flex-start;
  display: table-row;
  font-size: 14px;

  &.active {
    font-weight: 600;
  }

  &.inActive {
    opacity: .5;
  }
}

.legendColor {
  width: 8px;
  height: 8px;
  flex: 0 0 8px;
  border-radius: 50%;
  margin-right: 8px;
  display: block;
}

.legendLabel {
  display: table-cell;
  width: 90%;
  vertical-align: middle;
  padding: 0 0 16px;
}

.legendLabelInner {
  display: flex;
  align-items: baseline;
}

.legendValue {
  display: table-cell;
  width: 10%;
  flex: 1 1 50%;
  margin-right: auto;
  padding: 0 0 16px 24px;
}

.head {
  padding-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
}
