@import "../../../../../styles/config";

.cell {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.cellInnerWrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color:  rgb(var(--colors-black));
}

.headerSide {
  padding-right: 0;
}

.headerSide > div {
  background-color:  rgba(var(--colors-black), .75);
}

.dataSide {
  padding-right: 2px;
  background-color: rgba(var(--colors-black), .75);
}

.notVisible {
  display: none;
}
