.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(var(--colors-black), 0.5);
}

.spinnerWrapper {
  top: 50%;
  left: 50%;

  svg {
    width: 55px;
    height: 55px;
  }
}
