@import '../../../styles/config';

.wrapper {
  display: flex;
  color: rgb(var(--colors-gray-4));
  margin-left: auto;
  font-size: 16px;

  @include media('>=medium') {
    font-size: 20px;
  }

  @include media('>=large') {
    font-size: 24px;
  }
}

.performing,
.underPerforming {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 45px;
}

.underPerforming {
  margin-left: 5px;
}

.icon {
  margin-left: 5px;

  svg {
    width: 12px;
    height: 12px;
  }
}
