@import 'src/styles/mixins';

.wrapper {
  position: absolute;
  background-color: rgb(var(--colors-gray-7));
  overflow: hidden;
  z-index: 1;
  bottom: 0;
  transition: all .3s ease;
  width: 0;
  border-radius: 0 4px 4px 0;
  top: 0;
  right: 0;

  &.open {
    width: 50%;
  }

  &__bottom {
    left: 0;
    right: 0;
    top: 100%;
    width: auto;
    height: 0;

    .inner {
      width: 100%;
    }

    &.open {
      width: auto;
      height: 300px;
    }
  }
}

.inner {
  position: relative;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 35px 20px 20px;
  max-height: 100%;
  overflow: auto;

  @include scrollBarStyles;
}

.label {
  color: rgb(var(--colors-gray-4));
}

.content {
  margin-bottom: 18px;

  a {
    color: rgb(var(--colors-white));
    text-decoration: underline;
  }

  p {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.closeButton {
  position: absolute;
  top: 11px;
  right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  z-index: 1;
  cursor: pointer;
}

.closeButtonIcon {
  svg {
    width: 24px;
    height: 24px;
  }
}
