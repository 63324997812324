@import "../../../../../styles/config.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;
  border-top: 2px solid rgb(var(--colors-gray-6));
}

.infoText {
  font-size: 16px;
  color: rgb(var(--colors-gray-4));
  margin-top: 20px;

  @include media(">=small") {
    font-size: 20px;
  }
}
