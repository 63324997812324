@import "src/styles/config";

.wrapper {
  margin-top: 80px;
}

.widgets {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: stretch;

  @include media(">=xlarge") {
    flex-direction: row;
  }
}

.widgetItem {
  flex: 1 1 50%;
}

.capitalSectionHeader {
  margin-bottom: 16px;
}
