
.option {
  background-color: #2D2D2D;
  border-radius: 4px;
  margin-bottom: 8px;
  border-left: 4px solid transparent;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: rgb(var(--colors-lan-green));
  }

  &.dragging {
    background-color: rgb(var(--colors-gray-7));
  }

  &.group {
    background-color: rgb(var(--colors-gray-7));
    margin-bottom: 16px;
    border: none;
  }
}

.inner {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 10px 0 0;
}

.subItems {
  padding: 8px;
}

.groupLabel {
  font-size: 14px;
  color: rgb(var(--colors-gray-4));
  margin-left: auto;
}

.optionIcon {
  margin: 0 16px;
  color: rgb(var(--colors-lantern-main-color));

  svg {
    width: 10px;
    height: 18px;
  }
}
