.wrapper {
  position: fixed;
  cursor: pointer;
  pointer-events: none;
}

.labelWrapper {
  transform: rotate(-90deg);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left top;
  line-height: 30px;
  position: fixed;
  bottom: -25px;
  left: 0;
}
