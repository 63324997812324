@import 'src/styles/mixins';

.wrapper {
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 0;
  width: 448px;
  background-color: rgb(var(--colors-gray-8));
  border-radius: 8px 8px 0 0;
  padding: 24px 24px 0 24px;
  transform: translateY(100%);
  transition: all .3s ease;
  opacity: 0;

  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}

.title {
  font-size: 20px;
}

.buttons {
  display: flex;
  gap: 16px;
}

.top {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 24px;
  padding: 0 0 24px 0;
}

.content {
  transition: max-height .3s ease;
  max-height: 400px;

  &.isMinimized {
    max-height: 0;
  }
}

.cancelButtonWrapper {
  border-bottom: 1px solid rgb(var(--colors-gray-7));
}

.filesWrapper {
  max-height: 250px;
  overflow-y: scroll;
  padding-bottom: 24px;

  @include scrollBarStyles;
}

.cancelButton {
  display: inline-block;
  width: max-content;
  border: none;
  box-shadow: none;
  background: none;
  padding: 0;
  margin-bottom: 24px;
  font-size: 14px;
  color: rgb(var(--colors-gray-4));
  text-decoration: underline;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: none;
  }
}

.fileItem {
  padding: 24px 6px 0 0;
  display: flex;
  align-items: center;
  color: rgb(var(--colors-gray-4));
  gap: 5px;

  &.active {
    color: rgb(var(--colors-lantern-main-color));

    .icon {
      opacity: 1;
    }
  }
}

.fileName {
  display: block;
  white-space: nowrap;
  max-width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  opacity: .5;
}



