@import '../../../styles/config';

.wrapper {
  position: relative;
  margin: 0 0 80px;
  display: flex;
  align-items: flex-start;
}

.logo {
  margin-right: 24px;
  margin-top: 6px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  gap: 24px;
}

.subTitle {
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
  display: flex;
  height: 24px;
}

.warning {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 4px;
}

.asAtIcon {
  color: rgb(var(--colors-lan-rose));
}

.colorLine {
  width: 4px;
  height: 31px;
  margin-right: 24px;
  margin-top: 13px;
}

.titleCounter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 45px;
  font-size: 24px;
  font-weight: 400;
  border-radius: 6px;
  background: rgba(var(--colors-white), 0.12);
  padding: 8px;
}
