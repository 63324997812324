@import '../../../styles/config';

.sectionHeader {
  margin-bottom: 20px;
}

.fundsSectionWrapper {
  position: relative;
}

.fundsSection {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, calc(50% - 10px));

  @include media('>=xlarge') {
    gap: 24px;
  }

  @include media('>=xxlarge') {
    grid-template-columns: repeat(3, calc(33% - 7px));
  }
}

.sectionWidget {
  width: 100%;
}

.segmentedControl {
  display: block;
}

.selectDropdown {
  display: none;
}

.viewSwitchToggle {
  margin: 0 0 0 32px;
}

.buttonsWrapper {
  &:empty {
    display: none;
  }
}
