.table {
  width: 100%;

  td,th {
    width: 25%;
  }

  th {
    font-weight: 500;
    font-size: 14px;
    padding: 0 0 16px;
    text-align: left;
    white-space: nowrap;

    &:not(:first-child) {
      padding-left: 16px;
      text-align: right;
    }

    span {
      font-size: 12px;
      font-weight: 400;
    }
  }

  td {
    font-size: 12px;
    line-height: 16px;
    padding: 0 0 12px;
    vertical-align: top;

    &:not(:first-child) {
      padding-left: 16px;
      text-align: right;
    }
  }
}

.tableMarker {
  display: block;
  flex: 0 0 8px;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;
}

.firstCell {
  display: flex;
  align-items: baseline;
}