@import '../../../../styles/config.scss';
@import '../../../../styles/mixins';

.wrapper {
  position: relative;
}

.nameCell {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.table {
  border-collapse: collapse;
  table-layout: fixed;

  th {
    font-size: 12px;
    font-weight: 500;
  }

  td {
    color: rgb(var(--colors-white));
    font-weight: 400;
    word-break: break-word;
  }

  td,
  th {
    height: 56px;
    padding: 0 8px 0 0;

    &:first-of-type {
      padding-left: 8px;
    }
  }
}

.theadRow {
  color: rgb(var(--colors-gray-4));
}

.tbodyRow {
  border-top: 1px solid rgb(var(--colors-gray-5));
  font-size: 12px;
  &:hover + .tbodyRow {
    border-top: 1px solid transparent;
  }

  &:hover:not(.row__hover) {
    background-color: rgb(var(--colors-gray-7));
    border-top: 1px solid transparent;
  }

  &:first-of-type:not(:hover) {
    border-top: 1px solid rgb(var(--colors-gray-5));
  }

  &:first-of-type:not(.row__hover):hover {
    border-top: 1px solid transparent;
  }

  &:last-of-type:not(:hover) {
    border-bottom: 1px solid rgb(var(--colors-gray-5));
  }

  &:last-of-type:hover {
    border-bottom: 1px solid transparent;
  }
}

.tbodyRowExpanded {
  overflow: hidden;
  border: none;
  padding: 0;
}

.footerLabel {
  color: rgb(var(--colors-white));
  font-size: 20px;
  font-weight: 400;
}

.checkboxWrapper {
  margin: 20px 0 0 8px;
}

.actionButton {
  @include displayOnTableRowHover;
}
