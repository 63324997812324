.wrapper {
  height: 111px;
  background-color: rgb(var(--colors-black));
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 1;
  transition: all .3s ease;

  &.expanded {

    &.primary {
      height: 265px;
    }

    &.secondary {
      height: 388px;
    }
  }
}

.toggleButton {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(var(--colors-gray-8));
  display: flex;
  align-items: center;
  justify-content: center;
  right: 40px;
  top: -20px;
  border: none;
  cursor: pointer;
  transition: all .3s ease;
  color: #ffffff;

  &:hover {
    background-color: rgb(var(--colors-gray-7));
  }

  &.expanded {
    transform: rotate(180deg);
  }
}

.line {
  width: 100%;
  height: 1px;
  position: relative;
  border-top: 1px dashed rgb(var(--colors-gray-6));
  margin-bottom: 58px;
}

.lineInner {
  position: absolute;
  top: 0;
  left: 50px;
  right: 50px;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.endPoint {
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: rgb(var(--colors-gray-6));
  top: -4px;
  right: -4px;

  span {
    display: block;
    font-size: 14px;
    line-height: 18px;
    position: absolute;
    top: 21px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.eventGraph {
  background: linear-gradient(270deg, rgba(var(--colors-lan-violet), .25) 0%, rgba(153, 0, 255, 0.4) 100%);
  position: absolute;
  z-index: 2;
  bottom: 0;
  height: 50px;
  transition: opacity .3s ease, right .3s ease;
  right: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  div {
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-right: 10px;
  }
}
