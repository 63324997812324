@import '../../../../../styles/config.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 24px;

  .line {
    width: 4px;
    height: 24px;
    margin-top: 0px;
    margin-right: 12px;
    transition: all 0.2s ease-in-out;
  }

  .line__expanded {
    height: 32px;
    margin-top: 24px;
  }

  .line__grayscaled {
    filter: grayscale(1);
  }

  .name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    transition: all 0.2s ease-in-out;
    font-family: 'Halenoir';
  }

  .name_expanded {
    margin-top: 24px;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    overflow: visible;
    white-space: nowrap;

    @include media('>=xlarge') {
      font-size: 24px;
    }
  }

  .icon {
    margin-top: 0;
    margin-right: 12px;
  }

  .icon__expanded {
    margin-top: 24px;
  }

  .link {
    color: rgb(var(--colors-white));
  }

  .link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
