.actionButton {
  padding: 0px;
}

.nameCell {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.isCasting {
  background-color: rgb(var(--colors-red));
}