@import '../../../styles/mixins.scss';

.wrapper {
  padding: 24px 40px;
}

.input {
  width: 50%;
}

.actionButtons {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}

// Light theme buttons
@include buttons-light-theme;

.errorMessage {
  @include drawerFormError();
}
