.wrapper {
  display: inline-block;
  font-family: 'Halenoir';
  word-break: normal;
}

.suffix {
  vertical-align: baseline;
  font-family: 'HalenoirText';
}
