.wrapper {
  padding: 40px;
  background-color: rgba(var(--colors-black), .75);
  border-radius: 8px;
}

.membersList {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 40px;
}

.subTitle {
  font-weight: 500;
  font-size: 18px;
  color: rgb(var(--colors-gray-4));
  margin-bottom: 16px;
}

.assigneeRow {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 40px;
}
