@import "../../../styles/config";
@import "../../../styles/grids.scss";

.wrapper {
  width: 100%;
}

.sectionWrapper {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  gap: 16px;

  @include media(">=xlarge") {
    grid-template-columns: repeat(3, calc(33% - 7px));
  }
}

.portfolioSectionHeader {
  margin-bottom: 24px;

  @include media(">=xlarge") {
    margin-bottom: 16px;
  }
}

.portfolioInfoHeader {
  margin-bottom: 64px;

  @include media(">=xlarge") {
    margin-bottom: 80px;
  }
}

.portfolioPagination {
  margin-top: 25px;
}

.portfolioCompaniesHeader {
  margin-bottom: 24px;

  @include media(">=xlarge") {
    margin-bottom: 16px;
  }
}
