@import '../../../styles/mixins';

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  margin: 8px 0 0 0;
  border-radius: 8px;
  background-color: rgb(var(--colors-white));
  padding: 6px 0;
  box-shadow: 0 2px 8px rgba(var(--colors-black), 0.3);
  z-index: 1;
}

.dropdownInner {
  max-height: 264px;
  overflow: auto;
  padding: 10px 0;

  @include scrollBarStyles;

  &::-webkit-scrollbar-thumb {
    background-color: rgb(var(--colors-gray-4));
  }
}
