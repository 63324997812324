.wrapper {
  position: relative;
  display: inline-block;
  min-width: 40px;
  height: 24px;
  cursor: pointer;
  input {
    display: none;
  }

  &__primary {
    input:checked + .slider {
      background-color: rgb(var(--colors-lantern-main-color));
    }
  }

  &__secondary {
    input:checked + .slider {
      background-color: rgb(var(--colors-lan-green));
    }
  }
}

.mainWrapper,
.reverseMainWrapper {
  display: flex;
  align-items: center;

  &.disabled {
    pointer-events: none;
    .label {
      color: rgb(var(--colors-gray-4));
    }
  }
}

.reverseMainWrapper {
  flex-direction: row-reverse;

  .label {
    margin-left: 16px;
    font-size: 14px;
  }
}

.mainWrapper {
  .label {
    margin-right: 16px;
    font-size: 14px;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--colors-white), 0.2);
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: rgb(var(--colors-gray-8));
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(var(--colors-lantern-main-color));
}

input:checked + .slider:before {
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

