@import "../../../styles/config";

.wrapper {
  padding: 40px;
}

.header {
  padding: 0;
  border-bottom: 1px solid rgb(var(--colors-gray-7));
  margin: 0 0 40px;

  & > * {
    word-break: unset;
  }
}
