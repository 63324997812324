.tag {
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 14px;
  max-width: 100%;

  &__primary {
    padding: 7px 8px;
    border-radius: 6px;
  }

  &__secondary {
    padding: 3px 8px;
    border-radius: 24px;
  }
}

.label {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.removeButton {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  margin-left: 4px;
  cursor: pointer;
  opacity: .7;

  &:hover {
    opacity: 1;
  }
}

.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
}
