@import 'src/styles/mixins';

.buttons {
  @include drawerFormButtons();
}

.itemsContainer {
  margin-bottom: 24px;
}

.dataItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 8px 0;
  border-top: 1px solid rgb(var(--colors-gray-7));
  font-size: 14px;

  &:last-child {
    border-bottom: 1px solid rgb(var(--colors-gray-7));
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 12px;
}

.title {
  font-size: 18px;
  font-weight: 400;
}

.numberInputContainer {
  width: 118px;

  [class*='errorMessage'] {
    white-space: nowrap;
  }
}

.totalRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-family: 'HalenoirText', sans-serif;
  font-weight: 500;
}

.customInputWrapper {
  display: flex;
  gap: 10px;
  width: 270px;
}

.iconButtonWrapper {
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .spinner {
    color: rgb(var(--colors-gray-6));
    svg {
      width: 48px;
      height: 48px;
    }
  }
}

.errorMessage {
  @include drawerFormError();
}
