@import 'src/styles/mixins';

.buttons {
  @include drawerFormButtons();
}

.errorMessage {
  @include drawerFormError();
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .spinner {
    color: rgb(var(--colors-gray-6));

    svg {
      width: 48px;
      height: 48px;
    }
  }
}

.checkbox {
  display: flex;
  gap: 80px;
}

.checkboxLabel {
  font-size: 20px;
  font-weight: 500;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgb(var(--colors-gray-7));

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.groupTitle {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 16px;
}

.selectTransactionButton {
  margin-right: auto;
}

.previewImage {
  margin-bottom: 24px;
  max-height: 180px;
}

.fileNameTitle {
  [class*='title'] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 370px;
  }
}
