@import 'src/styles/mixins';

.buttons {
  @include drawerFormButtons();
  justify-content: flex-end;
}

.inner {
  padding: 16px 24px 40px;
  max-height: 500px;
  overflow-y: scroll;

  @include scrollBarStyles;

  &::-webkit-scrollbar-thumb {
    background-color: rgb(var(--colors-gray-4));
  }
}

.fileList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.fileListItem {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  font-size: 16px;
  line-height: 24px;
  word-break: break-word;

  &.notSupported {
    color: rgb(var(--colors-gray-6));

    .fileListItemIcon {
      color: rgb(var(--colors-red));
    }
  }
}

.fileTypeError {
  flex: 0 0 100%;
  font-size: 14px;
  line-height: 16px;
  color: rgb(var(--colors-red));
}

.fileListItemIcon {
  svg {
    width: 24px;
    height: 24px;
  }
}

.inputsGrid {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 8px));
  grid-column-gap: 16px;
  grid-row-gap: 24px;

  input {
    color: rgb(var(--colors-gray-7));
  }
}

.columnLabel {
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.accessInputRow {
  grid-column: 1 span /2;

  .columnLabel {
    margin-bottom: 8px;
  }
}

.hint {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;

  &:hover {
    background-color: rgb(var(--colors-gray-2));
  }

  svg {
    width: 14px;
    height: 14px;
  }
}

.tooltip {
  font-size: 14px;
  font-weight: 400;
  max-width: 364px;
  padding: 5px;

  b {
    font-weight: 600;
  }

  ul {

    li {
      margin-top: 18px;

      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: currentColor;
        margin-bottom: 2px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.tagsDropdown {
  svg {
    color: rgb(var(--colors-gray-6));
  }
}

// Light theme buttons
@include buttons-light-theme;
