@import '../../../styles/config';
@import '../../../styles/reorderingPanel';

.wrapper {
  position: relative;
}

.sectionHeader {
  margin-bottom: 20px;
}

.fundsSectionWrapper {
  position: relative;
}

.fundsSection {
  display: grid;
  gap: 16px;
  grid-template-columns: minmax(0, 1fr);

  @include media('>=large') {
    grid-template-columns: repeat(2, calc(50% - 10px));

    &.open {
      width: 50%;
      grid-template-columns: repeat(1, calc(100%));
    }
  }

  @include media('>=xlarge') {
    gap: 24px;

    &.open {
      width: 66%;
    }
  }

  @include media('>=xxlarge') {
    grid-template-columns: repeat(3, calc(33% - 7px));

    &.open {
      grid-template-columns: repeat(2, calc(50% - 10px));
    }
  }
}

.sectionWidget {
  width: 100%;
}

.segmentedControl {
  display: none;

  @include media('>=large') {
    display: block;
  }
}

.selectDropdown {
  display: block;
  margin-top: 10px;

  @include media('>=medium') {
    margin-top: 0;
  }

  @include media('>=large') {
    display: none;
  }
}

.viewSwitchToggle {
  margin: 10px 0 0 0;

  @include media('>=medium') {
    margin: 0 0 0 26px;
  }

  @include media('>=large') {
    margin: 0 0 0 32px;
  }
}

.actionButton {
  width: 100%;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  &:empty {
    display: none;
  }

  @include media('>=medium') {
    margin: 0 0 0 10px;
  }
}

.panel {
  @include reorderingPanel();
  top: 0;
  right: 0;

  &.open {
    width: 400px;
  }

  @include media('>=medium') {
    right: -24px;
  }

  @include media('>=large') {

    &.open {
      width: calc(50% + 14px);
    }
  }

  @include media('>=xlarge') {
    right: -32px;

    &.open {
      width: calc(33% + 22px);
    }
  }
}
