%simpleRechartsTooltip {
  display: flex;
  background: rgb(var(--colors-gray-7));
  border-radius: 8px;
  padding: 12px;

  .name,
  .value,
  .currency {
    font-size: 14px;
  }

  .name {
    font-weight: 600;
    margin-right: 5px;
  }

  .currency {
    margin-right: 5px;
  }
}
