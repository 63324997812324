@import '../../../styles/config';

.wrapper {
  width: 100%;
  padding: 0 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}

.left {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.right {
  display: flex;
  align-items: center;
  font-size: 24px;
  gap: 24px;
}

.label {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  font-family: 'Halenoir';

  &.large {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }

  @include media('>=xxlarge') {
    font-size: 26px;

    &.large {
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.count {
  border-radius: 6px;
  background: rgba(var(--colors-white), 0.12);
  padding: 4px;
  text-align: center;
  min-width: 45px;
  font-size: 24px;
  margin: 0 24px;
  line-height: 32px;
}
