.wrapper {
    border: 1px solid rgb(var(--colors-gray-6));
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    display: flex;
    transition: all 0.2s linear;
    line-height: 100%;
}

.small {
    min-height: 24px;
    min-width: 24px;
    width: 24px;
    font-size: 12px;
}

.medium {
    min-height: 32px;
    min-width: 32px;
    width: 32px;
    font-size: 16px;
}

.large {
    min-height: 48px;
    min-width: 48px;
    width: 48px;
    font-size: 24px;
}

.iconImage {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
    object-fit: contain;
    color: transparent;
}
