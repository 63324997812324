@import '../../../../styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message {
  padding: 24px;

  .fileName {
    font-weight: 500;
  }
}

.radioButtons {
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;

  div {
    padding: 12px 24px;
    border-bottom: none;
  }
}

.footerButtons {
  border-top: 1px solid rgb(var(--colors-gray-2));
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
}

// Light theme buttons
@include buttons-light-theme;
