.chevron {
  cursor: pointer;
  transition:  all .2s ease-in-out;
}

.chevron__up {
  //backface-visibility: hidden;
  margin-top: 24px;
  transform: rotate(180deg) scale(1.5);
}
