@import '../../../../../styles/config.scss';

.headerWrapper {
  width: 100%;
  padding: 24px 32px 0 32px;
  position: relative;
}

.headerLine {
  height: 4px;
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.fundNameRow {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.fundName {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: rgb(var(--colors-lantern-main-color));

  @include media('>=xlarge') {
    font-size: 28px;
  }

  &:hover {
    text-decoration-line: underline;
  }
}

.companiesCountWrapper {
  display: flex;

  svg {
    margin-left: 5px;
  }
}

.companiesCount {
  color: rgb(var(--colors-gray-4));
  font-size: 14px;
}

.fundInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 48px;

  &__dataNotCurrent {
    margin-bottom: 20px;
  }

  @include media('>=xxlarge') {
    margin-top: 20px;
  }

  .infoWrapper {
    margin-left: 8px;
    display: flex;
    align-items: flex-end;

    @include media('>=xlarge') {
      margin-left: 16px;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    color: rgb(var(--colors-gray-4));
    line-height: 18px;
  }

  .value {
    font-size: 14px;
    margin-left: 8px;
    color: rgb(var(--colors-lantern-main-color));

    sub {
      font-size: 14px;
    }

    @include media('>=xlarge') {
      font-size: 20px;
    }

    span {
      font-size: 12px;
      margin-right: 4px;

      @include media('>=xlarge') {
        font-size: 14px;
      }
    }
  }
}

.fundInfoFunds {
  display: flex;
  align-items: flex-end;
}

.year {
  background: rgb(var(--colors-gray-7));
  border-radius: 50px;
  font-size: 12px;
  padding: 4px 8px;
  margin-right: 8px;

  @include media('>=xxlarge') {
    font-size: 14px;
    line-height: 18px;
  }
}

.asAtWrapper {
  margin-bottom: 12px;
}

.asAt {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.asAtIcon {
  margin-right: 8px;
  color: rgb(var(--colors-lan-rose));
}
