@import 'src/styles/config';

.delta {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgb(var(--colors-gray-4));
  padding: 0 0 0 28px;
  position: relative;
  text-align: right;

  @include media('>=small') {
    font-size: 14px;
    line-height: 18px;
  }
}

.icon {
  position: absolute;
  left: 8px;
  top: 6px;

  svg {
    width: 10px;
    height: 6px;
  }
}

.valueLabel {
  white-space: nowrap;
}
