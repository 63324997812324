.tooltipWrapper {
  background: rgb(var(--colors-gray-7));
  padding: 12px;
  border-radius: 8px;
}

.quarter {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding-bottom: 8px;
  color: rgb(var(--colors-lantern-main-color));
  border-bottom: 1px solid rgb(var(--colors-gray-6));
}

.values {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-top: 8px;
  line-height: 18px;

  span {
    margin-right: 4px;
    font-weight: 600;
  }
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.icon {
  margin-right: 8px;
  color: rgb(var(--colors-gray-5));
}
