@import "../../../../../styles/config";

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-bottom: 1px solid rgb(var(--colors-gray-7));
  margin: 0 0 12px 0;
}

.groupHeaderRowLeft {
  width: 30%;
}

.groupHeaderRowRight {
  width: 70%;
  text-align: right;
}

.groupHeader {
  font-size: 24px;
  font-weight: 400;
  padding: 0 0 12px 0;
}

.groupHeaderGroup {
  font-size: 12px;
  font-weight: 500;
  color: rgb(var(--colors-gray-4));
}
