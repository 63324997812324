.wrapper {
  width: 100%;
  height: 100%;

  &__primary {
    .inner {
      background-color: rgba(var(--colors-black), 0.74);
    }
  }

  &__secondary {
    .inner {
      background-color: rgb(var(--colors-gray-8));
    }
  }
}

.tabsRow {
  [class*='tab'] {
    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
    }
  }
}
