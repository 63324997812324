@import "../../../styles/mixins";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  background: rgba(var(--colors-white));
  width: 606px;
  margin: 0;
  padding: 0;
  border-radius: 8px;
}


.pickerActionsWrapper {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  width: 100%;
  padding: 16px;
  border-top: 1px solid rgba(var(--colors-gray-2));
}

// Light theme buttons
@include buttons-light-theme;
