.wrapper {
  display: flex;
  align-items: center;
  padding-right: 8px;
  cursor: pointer;

  &:last-of-type {
    padding-right: 0;
  }

  &__start {
    justify-content: flex-start;
  }

  &__center {
    justify-content: center;
  }

  &__end {
    justify-content: flex-end;
  }
}

.headerColor {
  transition: color .720s linear;
  &:hover {
    color: rgb(var(--colors-white))
  }
}

.headerColor__sorted {
  color: rgb(var(--colors-white))
}
