.wrapper {
  position: relative;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.user {
  margin-left: 10px;
  user-select: none;
}

.userIcon {
  svg {
    width: 40px;
    height: 40px;
  }
}

.username {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.email {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgb(var(--colors-gray-5));
}
