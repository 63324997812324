@import '../../../styles/config.scss';

.breadcrumbs {
  margin-bottom: 30px;
}

.breadcrumbList {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.breadcrumb {
  margin: 0 7px;
  color: rgb(var(--colors-gray-4));
}

.breadcrumbLink {
  text-decoration: underline;
  color: rgb(var(--colors-gray-4));

  &:hover {
    color: rgb(var(--colors-white));
  }
}

.isActive {
  text-decoration: none;
  font-weight: 400;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--colors-gray-4));

  svg {
    width: 12px;
    height: 12px;
  }
}
