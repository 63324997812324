@import '../../../../styles/config';

.templateReportingTable {
  table-layout: fixed;
  //border-collapse: collapse;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: fit-content;
  empty-cells: show;
  padding: 0 0 40px 0;
  margin: 0;
  border: none;
  position: relative;

  tr {
    height: 100%;
    border: 0 solid transparent;
    padding: 0;
    margin: 0;
  }

  tr td:nth-last-child(2) {
    padding: 0;
  }

  .cellSelected {
    outline: none;
  }

  .selectionOverlay {
    position: absolute;
    border: 2px solid rgb(var(--colors-lan-green));
    pointer-events: none;
    background-color: rgba(var(--colors-lan-green), .2);
    outline: none;
    z-index: 0;
  }
}

.statusHeader {
  position: sticky;
  top: -1px;
  z-index: 1;

  tr:nth-child(2) td:nth-last-child(2) {
    background-color: rgba(var(--colors-black), .75);
  }
}

.statusHeaderStuck :is(.paddingCell, .emptyCell, .paginationCell, .templateHeaderCell, .historicalReportDateCell) {
  background-color: rgb(var(--colors-black));
}

.statusHeaderStuck :is(.paddingCell, .emptyCell, .paginationCell, .templateHeaderCell) > div {
  background-color: rgb(var(--colors-black));
  opacity: 1;
}

.collapseButtonCell {
  position: relative;
  width: 4px;
  padding: 24px 0 16px 0;
  margin: 0;
  overflow: visible;
  border-bottom: 1px solid transparent;
}

.chevronButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  background-color: rgb(var(--colors-gray-8));

  &:hover, &:focus {
    background-color: rgb(var(--colors-gray-8));
    outline: none;
  }
}

/* Cells */
.groupHeaderCell, .dataCell {
  margin: 0;
}

/* Rows */
.navigationRow {
  margin: 0;

  td:first-child > div {
    border-radius: 8px 0 0 0;
  }

  td:last-child > div {
    border-radius: 0 8px 0 0;
  }
}

.bottomRow {
  margin: 0;

  .emptyCell {
    border-top: 1px solid rgb(var(--colors-gray-7));
    height: 32px;
  }

  td:first-child > div {
    border-radius: 0 0 0 8px;
  }

  td:last-child > div {
    border-radius: 0 0 8px 0;
  }
}

.statusHeaderRow {
  margin: 0;

  .emptyCell {
    border-top: 1px solid rgb(var(--colors-gray-7));
  }

  .templateHeaderCell {
    border-top: 1px solid rgb(var(--colors-gray-7));
  }

  > :nth-last-child(2) > div {
    border-radius: 0 8px 0 0;
  }
}

.statusSubmissionDateRow {
  margin: 0;
}

.statusPeriodDateRow {
  margin: 0;

  .emptyCell {
    border-bottom: 1px solid rgb(var(--colors-gray-5));
  }
}

.groupHeaderRow {
  margin: 0;

  &:first-of-type .groupHeaderCell {
    padding: 19px 12px 19px 0;
  }

  &:not(.groupHeaderRow:first-of-type) .groupHeaderCell {
    border-top: 1px solid rgb(var(--colors-gray-7));
  }

  &:not(.groupHeaderRow:first-of-type) .emptyCell > div {
    border-top: 1px solid rgb(var(--colors-gray-7));
  }
}

.groupSubHeaderRow {
  margin: 0;

  .groupSubHeaderCell:first-of-type {
    text-align: left;
    width: 300px;
  }
}

.groupRow {
  margin: 0;

  .dataCell__readOnly > input {
    border-top: 1px solid rgb(var(--colors-gray-7));
  }

  .dataCell__editable > input {
    border-top: 1px solid rgb(var(--colors-gray-8));
  }

  .mandatoryIndicatorCell {
    border-top: 1px solid rgb(var(--colors-gray-7));
  }
}

.headerCell {
  text-align: center;
}

.headerCellEsgTemplate {
  vertical-align: top;
  text-align: left;
}

.subHeaderCellEsgTemplate:nth-of-type(2) {
  text-align: left;
}

