@import "../../../../../styles/config";

.cell {
  width: 4px;
  background-color: transparent;
  border-top: 1px solid transparent;
}

.notVisible {
  display: none;
}
