@import '../../../styles/config';

.wrapper {
  width: 100%;
}

.feeAnalysisHeader {
  margin-bottom: 16px;
}

.content {
  background-color: rgba(var(--colors-black), 0.74);
  border-radius: 8px;
  padding: 24px;
}

.feeAnalysisHeaderTreemap {
  margin-bottom: 18px;
}

.overlayWrapper {
  position: relative;
}

.feeAnalysisHeaderSankey {
  margin: 48px 0 0 0;

  > span {
    margin-bottom: auto;
  }
}

.feeAnalysisTable {
  background-color: transparent;
  font-family: 'HalenoirText';
}
