.wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.content {
  width: 50%;
  flex: 1 1 50%;
}

.chart {
  width: 50%;
  flex: 1 1 50%;
  max-width: 80px;
}

.value {
  font-size: 20px;
  font-weight: 400;

  sub {
    font-size: 14px;
  }
}
