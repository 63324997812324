@import '../../../../../styles/config';

.dataCell {
  margin: 0;
  background-color: rgba(var(--colors-black), .75);
  padding: 0 2px 0 0;
}

.inputWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;


}

.input {
  //outline: none 0;
  color: rgb(var(--colors-white));
  background-color: rgb(var(--colors-gray-7));
  width: 100%;
  height: 100%;
  padding: 18px 12px 19px 12px;
  border: none;
  border-top: 1px solid rgb(var(--colors-gray-8));

  text-align: right;
  font-size: 12px;
  font-family: 'HalenoirText';

  &:focus:not(.readOnly) {
    outline: none 0;
    color: rgb(var(--colors-white)) !important;
    background-color: rgb(var(--colors-gray-6));
    border: 1px solid rgb(var(--colors-lan-green));
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:hover:not(:focus, .readOnly) {
    outline-width: 0;
    color: rgb(var(--colors-white));
    background-color: rgb(var(--colors-gray-6));
    border: 1px solid rgb(var(--colors-gray-5));
  }

  &::placeholder {
    text-align: center;
  }

  &.fakeColumn {
    background-color: rgb(var(--colors-black));
  }

  &.fakeColumn::placeholder {
    color: rgb(var(--colors-black));
  }

  &ErrrorMessage:focus:not(.readOnly) {
    border: 1px solid rgb(var(--colors-red));
  }

  &ErrrorMessage:not(:focus) {
    border: 1px solid rgb(var(--colors-red));
  }
}

.readOnly {
  outline: none 0;
  border: none;
  border-top: 1 solid rgb(var(--colors-gray-7));
  margin: 0;
  padding: 19px 12px 19px 12px;
  width: 100%;
  height: 100%;
  color: white;
  background: rgb(var(--colors-gray-8));
}

.errorMessage {
  position: absolute;
  bottom: calc(50% + 16px);;
  right: calc(100% - 32px);
  white-space: nowrap;
  background: rgb(var(--colors-red));
  font-size: 12px;
  font-weight: 500;
  padding: 8px 12px;
  color: rgb(var(--colors-white));
  border-radius: 4px;
  display: none;

  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: rgb(var(--colors-red));
    position: absolute;
    bottom: -4px;
    right: 12px;
    transform: rotate(45deg);
  }
}

.errorIcon {
  display: none;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 8px;
  top: calc(50% - 16px / 2);
  color: rgb(var(--colors-red));
}

.inputWrapper .errorMessage__active,
.inputWrapper .errorIcon__active {
  display: block;
}

.notVisible {
  display: none;
}
