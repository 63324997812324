/* Global styling */
@import './normalize';
@import './reset';
@import './box-sizing';
@import './config';
@import './theme';
@import './functions.scss';
@import './toast-messages.scss';

@font-face {
  font-family: 'Halenoir';
  src: url('../assets/fonts/Halenoir-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Halenoir';
  src: url('../assets/fonts/Halenoir-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Halenoir';
  src: url('../assets/fonts/Halenoir-DemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'HalenoirText';
  src: url('../assets/fonts/HalenoirText-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'HalenoirText';
  src: url('../assets/fonts/HalenoirText-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'HalenoirText';
  src: url('../assets/fonts/HalenoirText-DemiBold.otf') format('opentype');
  font-weight: 600;
}

// Basics
html,
body,
#root {
  min-width: 320px;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'HalenoirText', sans-serif;
  font-variant-ligatures: none;
  color: rgb(var(--colors-lantern-main-color));
}

// fixes inconsistent API issue in `svg-sprite-loader`
// see: https://github.com/kisenka/svg-sprite-loader/issues/123
.sprite-symbol-usage {
  display: block !important;
}

@supports (background-attachment: fixed) {
  body {
    background: rgba(0, 0, 0, 0.95) url('../assets/images/background.png') no-repeat center center fixed;
    background-size: cover;
  }
}

// Workaround for Safari versions 14 - 15.3
@supports not (background-attachment: fixed) {
  body:before {
    content: '';
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.95) url('../assets/images/background.png') no-repeat center center;
    background-size: cover;
    position: fixed;
  }
}

button {
  font-family: inherit;
  font-variant-ligatures: none;
}

input, textarea {
  font-variant-ligatures: none;
}
