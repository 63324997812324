.wrapper {
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid rgb(var(--colors-gray-2));

  .radio {
    appearance: none;

    border-radius: 50%;
    width: 16px;
    height: 16px;

    border: 2px solid rgb(var(--colors-gray-5));
    transition: 0.1s all linear;
    margin-right: 21px;
    margin-left: 0;
  }

  .radio:checked {
    border: 5px solid rgb(var(--colors-lan-green));
  }

  .label {
    white-space: nowrap;
    font-size: 16px;
  }

  &:last-child {
    border-bottom: none;
  }
}
