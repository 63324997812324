.wrapper {
  background-color: rgb(var(--colors-lantern-dark));
  border-radius: 0 8px 8px 0;
  margin-bottom: 40px;
}

.controllersWrapper {
  padding: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.placeholder {
  display: flex;
  flex-direction: column;
  height: 400px;
  align-items: center;
  justify-content: center;
}

.placeholderIcon {
  display: block;
  margin: 0 auto 24px;

  svg {
    width: 48px;
    height: 48px;
  }
}

.placeholderTitle {
  font-size: 24px;
  margin-bottom: 16px;
  text-align: center;
}

.placeholderText {
  max-width: 500px;
  text-align: center;
}

.dateRangeWrapper {
  display: flex;
  align-items: center;
  padding: 16px 0;
  margin: 0 24px;
  border-bottom: 1px solid rgb(var(--colors-gray-6));
  gap: 24px;
}

.dateRangeSelector {
  border: none;
  color: rgb(var(--colors-gray-5));
}

.chart {
  position: relative;
}

.overlay {
  z-index: 1;
}

.yAxisLabel {
  color: rgb(var(--colors-lantern-main-color));
}
