@import 'src/styles/chart-data-dashboard';

.wrapper {
}

.filterWrapper {
  @extend #filterWrapper;
}

.additionalFilter {
  @extend #additionalFilter;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.additionalFilterLabel {
  @extend #additionalFilterLabel;
  display: flex;
  align-items: center;
  gap: 8px;
}

.infoIconWrapper {
  cursor: pointer;
}

.infoTooltip {
  max-width: 257px;
}

.select {
  @extend #select;
}

.chart {
  @extend #chartWrapper;
}

.chartLabel {
  @extend #chartLabel;
}

.overlayWrapper {
  position: relative;
}

.tableWrapper {
  margin-top: 14px;
  padding: 32px 0 6px 0;
  background-color: rgb(var(--colors-black));
  border-radius: 0 0 6px 6px;
}

.tableWrapperTitle {
  margin-bottom: 32px;
  font-size: 12px;
  color: rgb(var(--colors-gray-5));
  font-weight: 500;
  padding: 0 40px;
}
