.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  display: none;
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  background-color: rgb(var(--colors-red));
  border-radius: 50%;
}

.active {
  display: block;
}
