@import "../../../../../styles/config";

.cell {
  margin: 0;
  padding: 0;
  color: rgb(var(--colors-white));
  background-color: rgba(var(--colors-black), .75);
  text-align: center;
}

.cellInnerWrapper {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notVisible {
  display: none;
}
