@import '../../../../../styles/config';

.cell {
  margin: 0;
  padding: 0;
  background-color: rgba(var(--colors-black), .75);
}

.cellInnerWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0 16px 0;
  font-size: 12px;
  span {
    text-transform: lowercase;
  }
}

.notVisible {
  display: none;
}
